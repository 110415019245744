import { useState, useEffect } from "react";
import { usePC } from "@unbnd-operations/react-hooks";
import styled from "styled-components";

import CallBartender from "../../components/custom/UI/CallBartender";

import Navigation from "../../components/custom/Navigation";
import CocktailUI from "../../components/custom/CocktailUI";
import { useCurrentBottle } from "../../global/AppStore";
import { useInitialiseBar } from "../../global/ScanStore";
import { useResetUI } from "../../global/CocktailStore";

const ExperienceWrapper = styled.div`
  height: 100%;
  width: 100%;
  pointer-events: none;
  transition: opacity 300ms linear;
  color: #ffffff;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
  }

  &.blue-bg {
    background-color: var(--backgroundOverlay);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    background: linear-gradient(
      0deg,
      rgba(12, 35, 64, 0.6) 0%,
      rgba(12, 35, 64, 0) 100%
    );

    z-index: -1;
  }

  .sync-prompt {
    width: 196px;
    height: 76px;
    position: absolute;
    top: 63px;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(4px);
    color: #000;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    ${"" /* padding: 10px; */}

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p:first-of-type {
      padding-top: 6px;
    }

    p {
      font-size: 18px;
      font-family: var(--primaryFont);
      text-align: right;
    }

    .close-btn-sync {
      position: absolute;
      top: 8px;
      left: 8px;
    }
  }
`;

const Experience = ({ active }) => {
  const [isBarTriggered, initialiseBar] = useInitialiseBar();
  const [currentBottle, currentBottleId] = useCurrentBottle();
  const resetUI = useResetUI();

  const [showUI, setShowUI] = useState(false);

  const [showBartenderPrompt, setShowBartenderPrompt] = useState(false);

  usePC({
    event: "bar:animation:end",
    callback: () => {
      setShowUI(true);
      setShowBartenderPrompt(true);
    },
  });

  const [triggerBar] = usePC({
    event: "bottle:init",
    debug: true,
  });

  useEffect(() => {
    if (!currentBottleId) {
      setShowUI(false);
      resetUI();
      return;
    }
    initialiseBar();

    const barTO = setTimeout(() => {
      triggerBar();
      clearTimeout(barTO);
    }, 500);

    return () => {
      clearTimeout(barTO);
    };
  }, [currentBottleId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ExperienceWrapper className={`experience-wrapper ${active && "active"}`}>
      {/* <ScanLabel active={!showUI} /> */}

      {isBarTriggered && currentBottle && (
        <>
          {/* {showBartenderPrompt && ( */}
          <CallBartender
            active={showBartenderPrompt && showUI}
            prompt='Tap to call for the bartender'
            closePrompt={() => setShowBartenderPrompt(false)}
          />
          {/* )} */}

          <Navigation showNavbar={true} />
        </>
      )}

      {currentBottle && (
        <CocktailUI
          showUI={showUI}
          // isCocktailMenuOpen={isCocktailOpen}
          // toggle={handleCocktailMenu}
          // cocktailHandler={cocktailMenuHandler}
        />
      )}
    </ExperienceWrapper>
  );
};

export default Experience;
