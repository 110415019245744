import { useState } from "react";

import Navbar from "./UI/Navbar";
import AboutPopup from "./UI/AboutPopup";
import LatestOffers from "./UI/LatestOffers";
import {
  useCurrentBottle,
  useMainData,
  useRegionData,
} from "../../global/AppStore";

function Navigation({ showNavbar }) {
  const [currentBottle] = useCurrentBottle();
  const mainData = useMainData();
  const region = useRegionData();

  const [showAboutProduct, setShowAboutProduct] = useState(false);
  const [showLatestOffers, setShowLatestOffers] = useState(false);
  // const [showFindStore, setShowFindStore] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleInfoMenuPick = async (itemId) => {
    switch (itemId) {
      case "about":
        setShowAboutProduct(true);
        break;

      case "latest-offers":
        setShowLatestOffers(true);
        break;

      // case "find-store":
      //   setShowFindStore(true);
      //   break;

      case "share":
        const shareData = {
          url: window.location.origin,
          title: "Lyre's Impossble Bar",
        };

        try {
          await navigator.share(shareData);
        } catch (error) {
          console.log(`error navigator.share`, error);
        }

        break;

      case "visit":
        window.open(mainData.site_urls[region.region.short], "_blank");
        break;
      default:
        return;
    }
  };

  return (
    <>
      {(showNavbar || isMenuOpen) && (
        <>
          <Navbar
            handleMenuItem={handleInfoMenuPick}
            isOpen={isMenuOpen}
            setIsOpen={(state) => setIsMenuOpen(state)}
          />

          <AboutPopup
            active={showAboutProduct}
            close={() => setShowAboutProduct(false)}
            data={currentBottle}
          />

          {/* {showFindStore && (
            <FindStore
              active={showFindStore}
              close={() => setShowFindStore(false)}
            />
          )} */}

          <LatestOffers
            active={showLatestOffers}
            close={() => setShowLatestOffers(false)}
          />
        </>
      )}
    </>
  );
}

export default Navigation;
