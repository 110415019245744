import { useState } from "react";
import styled from "styled-components";
import OrnateWrapper from "../../components/custom/layout-elements/OrnateWrapper";
import LyreBird from "../../assets/images/logos/lyres-bird.svg";

import CopyIcon from "../../assets/images/icons/copy-icon.svg";

import { useConfig } from "../../global/ConfigState";

const BrowserWrapper = styled.div`
  width: 100%;
  height: 100%;

  .supported-browsers {
    font-size: 0.75rem;
    font-family: var(--headingFont);
    font-style: italic;
    color: var(--primaryColour);
    margin-top: 14px;
  }

  .btn-copy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    bottom: 62px;
    transform: translateX(-50%);
    font-family: var(--primaryFont);
    color: var(--primaryColour);
    img {
      margin-bottom: 18px;
    }
  }

  .prompt {
    color: var(--primaryColour);
    text-transform: uppercase;
    border-bottom: 2px solid var(--primaryColour);
    font-family: var(--primaryFont);
    font-size: 1.375rem;
    padding-bottom: 18px;
  }

  .prompt-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 38px;
  }

  .lyre-bird {
    width: 28px;
    position: absolute;
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Browser = () => {
  const [isCopied, setIsCopied] = useState(false);
  const { deviceInfo } = useConfig();
  const url = window.location.href;

  const copyToClipboard = () => {
    if (!url) return;

    window.navigator.clipboard
      .writeText(url)
      .then(() => setIsCopied(true))
      .catch((err) => console.warn("Copy to clipboard failed.", err));
  };

  return (
    <BrowserWrapper>
      <OrnateWrapper top={true} bottom={true} padding={20}>
        <img className='lyre-bird' src={LyreBird} alt='Lyre Bird' />

        <div className='prompt-wrapper'>
          <p className='prompt'>
            Your browser does not support the features required for webar. Click
            the button below to copy the link, then paste it to a supported
            browser.
          </p>

          <p className='supported-browsers'>
            {deviceInfo.os === "Apple"
              ? "Safari, Chrome"
              : `${
                  deviceInfo.browser === "Samsung Browser"
                    ? "Samsung Browser, "
                    : ""
                }Chrome, Firefox`}
          </p>
        </div>
        <button className='btn btn-copy' onClick={copyToClipboard}>
          <img src={CopyIcon} alt='Copy Icon' />
          {isCopied ? "Link Copied" : "Copy Link"}
        </button>
      </OrnateWrapper>
    </BrowserWrapper>
  );
};

export default Browser;
