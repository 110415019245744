import styled from "styled-components";

import CocktailIcon from "../../../assets/images/icons/cocktail-icon.svg";
import CircleButton from "./CircleButton";

const InteractBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 28px;
  right: 30px;
  text-align: right;

  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms linear;
  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  .interact-circular-btn {
    width: 44px;
    height: 44px;
    img {
      width: 44%;
    }
  }
  span {
    margin-right: 12px;
    font-family: var(--primaryFont);
    color: var(--primaryColour);
    font-size: 1.2rem;
    padding-top: 3px;
  }
`;

function InteractBtn({ viewRecipe, active }) {
  return (
    <InteractBtnWrapper
      className={`interact-btn ${active ? "active" : ""}`}
      onClick={viewRecipe}
    >
      <span>
        Show me
        <br />
        the recipe
      </span>
      <CircleButton size={50} className='interact-circular-btn'>
        <img src={CocktailIcon} alt='Cocktail Icon' />
      </CircleButton>
    </InteractBtnWrapper>
  );
}

export default InteractBtn;
