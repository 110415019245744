import styled from "styled-components";

const CocktailMenuPromptWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--backgroundOverlay);

  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 10;

  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms linear;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  p {
    color: var(--primaryColour);
    text-transform: uppercase;
    border-bottom: 2px solid var(--primaryColour);
    font-family: var(--primaryFont);
    font-size: 1.5rem;
    margin-bottom: 54px;
  }
`;

const CocktailMenuPrompt = ({ active, icon, prompt, close }) => {
  return (
    <CocktailMenuPromptWrapper
      onClick={close}
      className={`cocktail-menu-prompt-wrapper ${active ? "active" : ""}`}
    >
      <img src={icon} alt='icon' />
      <p dangerouslySetInnerHTML={{ __html: prompt }}></p>
    </CocktailMenuPromptWrapper>
  );
};

export default CocktailMenuPrompt;
