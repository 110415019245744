import { useState } from "react";
import { validateValues } from "./Validator";
import LoaderIcon from "../../assets/images/loader.png";
import styled from "styled-components";
import Form from "./Form";

import { useRegionData } from "../../global/AppStore";

const ContactFormWrapper = styled.div`
  .form-group {
    input {
      width: 100%;
      height: 50px;
      background-color: #f8f5ef;
      border: none;
      border-bottom: 2px solid #d4a139;
      text-align: center;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 58px;
      height: 58px;
      background-color: #0c2340;
      border-radius: 50%;
    }
  }

  .loader-wrapper {
    width: 100%;
    height: 100%;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-success {
    padding: 15px 0;
    color: #000;
    font-family: var(--headingFont);
  }

  .loader {
    transform-origin: center center;
    width: 48px;
    height: 48px;
    position: relative;

    img {
      position: absolute;
      left: 50%;
      top: 50%;

      animation: spin 1000ms infinite linear;
    }
  }

  .form-group:last-child {
    margin-top: 26px;
  }
`;

const formData = [
  {
    title: "First Name*",
    placeholder: "First Name",
    name: "cd_firstName",
    type: "text",
    value: "",
  },
  {
    title: "Last Name*",
    placeholder: "Last Name",
    name: "cd_lastName",
    type: "text",
    value: "",
  },
  {
    title: "Email*",
    placeholder: "example@email.com",
    name: "email",
    type: "email",
  },
  {
    title: "Submit",
    type: "submit",
  },
];

function LyresContactForm() {
  const { region } = useRegionData();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  const submit = async (data) => {
    setIsSubmitting(true);
    delete data[undefined];
    const body = {
      ...data,
      ...region.formInfo,
    };
    const encoded = new URLSearchParams(body).toString();
    fetch("https://email.lyres.co/signup.ashx", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "manual",
      body: encoded,
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
        setFormSuccess(true);
      });
  };

  return (
    <ContactFormWrapper>
      {!formSuccess ? (
        <>
          {isSubmitting ? (
            <div className='loader-wrapper'>
              <div className='loader'>
                <img src={LoaderIcon} alt='Loader Icon' />
              </div>
            </div>
          ) : (
            <Form data={formData} submit={submit} validation={validateValues} />
          )}
        </>
      ) : (
        <div className='form-success'>
          <p>Thank you for subscribing!</p>
          <p>We will be in touch shortly.</p>
        </div>
      )}
    </ContactFormWrapper>
  );
}

export default LyresContactForm;
