const regions = [
  {
    title: "Rest of World",
    short: "World",
    measure: "metric",
  },
  {
    title: "Australia",
    short: "AU",
    measure: "metric",
    formInfo: {
      userid: 257816,
      SIG3ffbe7cc1503584df2cb8980ee6724a9057fb4c92d231d26c92107403ee19c11: "",
      addressbookid: 9169238,
      programid: 5248,
    },
    countries: ["Australia"],
  },
  {
    title: "New Zealand",
    short: "NZ",
    measure: "metric",
    countries: ["New Zealand"],
  },
  {
    title: "United States",
    short: "US",
    measure: "ounce",
    formInfo: {
      userid: 259626,
      SIG08cb5a44abde4c6f11d047264a5b1380aa83500ef975e29723f1b4613332132f: "",
      addressbookid: 10012101,
      programid: 5723,
    },
    countries: ["United States"],
  },
  {
    title: "United Kingdom",
    short: "UK",
    measure: "part",
    formInfo: {
      userid: 259627,
      SIG7f145096960a403c22f8d7eafe13f88fd8432649b44c1acce3aea4015d3861c3: "",
      addressbookid: 10012129,
      programid: 5381,
    },
    countries: ["United Kingdom"],
  },
  {
    title: "Europe",
    short: "EU",
    measure: "metric",
    formInfo: {
      userid: 298599,
      SIGab873791905d2806633b5c2db5ac1fc3a3c167f298a82dffba4df7462b426550: "",
      addressbookid: 10012055,
      programid: 7366,
    },
    countries: [
      "Ireland",
      "Netherlands",
      "Austria",
      "Germany",
      "Italy",
      "Sweden",
      "Spain",
      "France",
      "Norway",
      "Denmark",
      "Poland",
      "Switzerland",
      "Belgium",
      "Finland",
      "Iceland",
      "Luxumbourg",
    ],
  },
  {
    title: "Canada",
    short: "CA",
    measure: "part",
    formInfo: {
      userid: 314495,
      SIG7f1e50eff9c083d36f4d9060aa47872388e6108380be194a28cc2edbb7b58930: "",
      addressbookid: 10012136,
      programid: 11452,
    },
    countries: ["Canada"],
  },
  {
    title: "South East Asia",
    short: "SEA",
    measure: "metric",
    formInfo: {
      userid: 312439,
      SIG93c9114313fdcc1324ef1acd4eeba3a9c1c2ab3aebd8983e14781929d78ddd41: "",
      addressbookid: 10012141,
      programid: 9335,
    },
    countries: [
      "Singapore",
      "Hong Kong",
      "Thailand",
      "Malaysia",
      "Indoneasia",
      "Taiwan",
      "Philippines",
      "Vietnam",
    ],
  },
  {
    title: "China",
    short: "CN",
    measure: "metric",
    countries: ["China"],
  },
  {
    title: "South Africa",
    short: "ZA",
    measure: "metric",
    formInfo: {
      userid: 324430,
      SIG536a8c3504e7565d79d746bb66d140428ada05bfbe07d71e8fd5d277ef5b95f7: "",
      addressbookid: 13736493,
      programid: 18363,
    },
    countries: ["South Africa"],
  },
  {
    title: "Middle East",
    short: "ME",
    measure: "metric",
    formInfo: {
      userid: 338403,
      SIG58f7ecd810a46dfb471455527103f66b36c81b8832cba5c9c3c38b27bedb56e6: "",
      addressbookid: 13736518,
    },
    countries: [
      "United Arab Emirates",
      "Saudi Arabia",
      "Kuwait",
      "Qatar",
      "Oman",
      "Bahrain",
    ],
  },
];

export default regions;
