import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const CookiePromptWrapper = styled.div`
  background-color: #cecece;
  position: absolute;
  top: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  padding: 0.5rem 1rem;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  z-index: 100;

  .cookie-paragraph {
    font-size: 0.75rem;
    line-height: 1.3;
    padding-right: 1rem;
  }

  a {
    text-decoration: underline;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-self: end;
    margin-top: 0.5em;

    button {
      background-color: white;

      border-radius: 0.5rem;
      padding: 0.5em 1em;

      width: 100%;

      margin: 0.25em;

      font-size: 0.75em;

      &.accept {
        background-color: var(--primaryColour);
        color: white;
      }

      &.reject {
        background-color: transparent;
      }
    }
  }

  .close-btn {
    right: 0;
    top: 0;
    background-color: transparent;
    position: relative;

    span {
      &::before,
      &::after {
        background-color: #000;
      }
    }
  }
`;

const CookiePrompt = ({ onAccept, onReject }) => {
  return (
    <CookiePromptWrapper id='cookie-prompt'>
      <p className='cookie-paragraph'>
        This site uses cookies to improve the experience. By clicking start, you
        accept our <Link to='/cookie-policy'>cookie policy.</Link>
      </p>
      <div className='buttons'>
        <button onClick={onReject} className='reject'>
          Reject
        </button>
        <button onClick={onAccept} className='accept'>
          Accept
        </button>
      </div>
    </CookiePromptWrapper>
  );
};

export default CookiePrompt;
