import ReactGA from "react-ga";

// Set innerHTML
export function setHTML(content) {
  return {
    __html: content,
  };
}

// Handle link outs
export function linkOut(url, sameTab = false) {
  ReactGA.event({
    category: "Links",
    action: "Link Out",
    label: url,
  });

  window.open(url, sameTab ? "_self" : "_blank");
}

// Have a coffee break
export function coffeeBreak(time) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

export function filterBottles(bottles, region) {
  return bottles.filter((bottle) => {
    // If we're not in ME, filter out ME bottles
    if (
      region.short !== "ME" &&
      bottle.id === "italian_spritz_middle_east_700ml"
    ) {
      return false;
    }

    // If we're in ME, filter out non-ME bottles
    if (region.short === "ME" && bottle.id === "italian_spritz_700ml") {
      return false;
    }

    // If bottle is 200ml, filter out bottle
    if (bottle.size === "200ml") {
      return false;
    }

    return true;
  });
}
