const theme = {
  color: {
    primary: "#D4A139",
    secondary: "#0C2340",
    background: "#F8F5EF",
    backgroundOverlay: "#081B33B3",
    backgroundOverlaySolid: "#081B33",
    text: {
      normal: "#1C1C1C",
      inverted: "#FFFFFF",
    },
    base: {
      dark: "#212529",
      light: "#FFFFFF",
      grey: "#DEDBDB",
      black: "#000000",
    },
    placement: {
      default: "#757575",
      success: "#1AE8B6",
      error: "#FF866B",
    },
  },
};

export default theme;
