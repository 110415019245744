import styled from "styled-components";

import CornerOrnateImage from "../../../assets/images/icons/corner-ornate.svg";

const OrnateStyles = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .ornate {
    position: absolute;
    z-index: 2;
    width: ${(props) => props.size}px;
  }
  .TL {
    top: ${(props) => props.padding}px;
    left: ${(props) => props.padding}px;
  }
  .TR {
    top: ${(props) => props.padding}px;
    right: ${(props) => props.padding}px;
    transform: rotate(90deg);
  }
  .BL {
    bottom: ${(props) => props.padding}px;
    left: ${(props) => props.padding}px;
    transform: rotate(270deg);
  }
  .BR {
    bottom: ${(props) => props.padding}px;
    right: ${(props) => props.padding}px;
    transform: rotate(180deg);
  }
`;

const OrnateWrapper = ({ top, bottom, padding, size, children, className }) => {
  return (
    <OrnateStyles
      id='ornate-styles'
      padding={padding}
      size={size ? size : 41}
      className={className ? className : ""}
    >
      {top && (
        <>
          <img
            src={CornerOrnateImage}
            alt='Ornate Top Left'
            className='ornate TL'
          />
          <img
            src={CornerOrnateImage}
            alt='Ornate Top Right'
            className='ornate TR'
          />
        </>
      )}

      {bottom && (
        <>
          <img
            src={CornerOrnateImage}
            alt='Ornate Bottom Left'
            className='ornate BL'
          />
          <img
            src={CornerOrnateImage}
            alt='Ornate Bottom Right'
            className='ornate BR'
          />
        </>
      )}
      {children}
    </OrnateStyles>
  );
};

export default OrnateWrapper;
