import { useRef, useState, useEffect } from "react";
import { usePC } from "@unbnd-operations/react-hooks";
import styled from "styled-components";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";

import { useRegionData } from "../../../global/AppStore";

import ReactGA from "react-ga";

import OrnateWrapper from "../layout-elements/OrnateWrapper";
import CircleButton from "../layout-elements/CircleButton";

import DownloadIcon from "../../../assets/images/icons/download-icon.svg";
import {
  useIntroInfo,
  useIsTrayOpen,
  useToggleTray,
} from "../../../global/CocktailStore";

const CloseTrayTrigger = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
  background-color: var(--backgroundOverlay);
  pointer-events: none;
  opacity: 0;
  transition: opacity 150ms linear;

  &.active {
    pointer-events: auto;
    opacity: 1;

    .close-btn {
      opacity: 1;
      transition: opacity 200ms linear 100ms;
    }
  }

  .close-btn {
    position: absolute;
    top: 14%;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    width: 26px;
    height: 26px;
    padding: 0;
    border: 1px solid #fff;
    border-radius: 100%;
    z-index: 10;
    opacity: 0;
    transition: opacity 200ms linear;
  }

  .close-btn > span {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
  }

  .close-btn > span::after,
  .close-btn > span::before {
    content: "";
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
  }

  .close-btn > span::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .close-btn > span::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

const SlideUpTrayWrapper = styled.div`
  background-color: #eee;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80%;
  transform: translateY(100%);
  transition: transform 300ms ease-in-out, opacity 150ms linear 100ms;
  opacity: 0;
  pointer-events: none;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  font-family: var(--headingFont);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 7;

  h3,
  p {
    font-family: var(--headingFont);
    font-size: 0.875rem;
    color: #000;
  }

  &.active {
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto;
  }
  .navigation-wrapper {
    height: 100%;
  }
  .keen-slider {
    height: 100%;
  }

  .slider-wrapper {
    flex-grow: 1;
  }
  .keen-slider__slide {
    .main-heading {
      font-family: var(--headingFont);
      text-transform: uppercase;
      color: #000;
      font-size: 1.5rem;
      padding-top: 3rem;
      margin-bottom: 1rem;
    }
    .sub-heading {
      padding-top: 10px;
    }
  }

  .single-line-copy {
    color: #000;
    font-size: 0.875rem;
  }
  .dots {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    padding: 10px 0;
    justify-content: center;
  }

  .dot {
    border: none;
    width: 10px;
    height: 10px;
    background: #c5c5c5;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
  }

  .dot:focus {
    outline: none;
  }

  .dot.active {
    background: #000;
  }
  .hero-image {
    position: relative;
    .download-btn {
      position: absolute;
      bottom: 12px;
      right: 15px;
    }
  }
`;

const SlideUpTray = ({ data }) => {
  const visible = useIsTrayOpen();
  const toggleTray = useToggleTray();
  const { hasViewedRecipe } = useIntroInfo();

  const { units } = useRegionData();

  const trayRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
    centered: true,
    loop: true,
  });

  useEffect(() => {
    if (visible) {
      ReactGA.event({
        category: "User",
        action: "Recipe Viewed",
        label: data.id,
      });
    }
  }, [visible]);

  const displayGarnishes = (garnishes) => {
    if (typeof garnishes === "object") {
      return garnishes.join(", ");
    } else {
      return garnishes;
    }
  };

  useEffect(() => {
    if (!slider) return;
    if (visible) return;

    slider.moveToSlide(0);
  }, [visible, slider]);

  const [audioLearn] = usePC({
    event: "audio:learn",
  });

  useEffect(() => {
    if (!hasViewedRecipe) return;
    if (!visible) return;

    audioLearn();
  }, [hasViewedRecipe, visible]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <CloseTrayTrigger
        className={visible ? "active" : ""}
        onClick={toggleTray}
      >
        <button class='close-btn'>
          <span></span>
        </button>
      </CloseTrayTrigger>

      <SlideUpTrayWrapper
        id='slide-up-tray'
        className={`${visible ? "active" : ""}`}
        ref={trayRef}
      >
        {/* <CloseLine elemRef={trayRef} close={toggleTray} /> */}

        <div className='hero-image'>
          {data && <img src={data.recipe.image} alt='Recipe Feature' />}
          {data && (
            <a
              href={data.pdf}
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                ReactGA.event({
                  category: "User",
                  action: "Recipe Downloaded",
                  label: data.id,
                });
              }}
            >
              <CircleButton className='download-btn' size={50}>
                <img src={DownloadIcon} alt='Download Icon' />
              </CircleButton>
            </a>
          )}
        </div>

        <div className='slider-wrapper'>
          <OrnateWrapper top={true} bottom={true} padding={16}>
            <div className='navigation-wrapper'>
              <div ref={sliderRef} className='keen-slider'>
                <div className='keen-slider__slide ingredients'>
                  <h2 className='main-heading'>Ingredients</h2>
                  {data.recipe.ingredients.map((item, index) => (
                    <div className='single-line-copy-wrapper' key={index}>
                      <p className='single-line-copy'>
                        {item.amount && (
                          <span className='quantity'>{item.amount}</span>
                        )}
                        <span className='measurement'>{item[units]} </span>
                        {item.name}
                      </p>
                    </div>
                  ))}
                </div>
                <div className='keen-slider__slide method'>
                  <h2 className='main-heading'>Method</h2>
                  {data.recipe.method.map((item, index) => (
                    <p
                      className='single-line-copy'
                      key={index}
                      dangerouslySetInnerHTML={{ __html: item }}
                    ></p>
                  ))}
                  {data.recipe.glass && (
                    <>
                      <h3 className='sub-heading'>Glass</h3>
                      <p className='single-line-copy'>{data.recipe.glass}</p>
                    </>
                  )}
                  {data.recipe.garnish && (
                    <>
                      <h3 className='sub-heading'>Garnish</h3>
                      <p className='single-line-copy'>
                        {displayGarnishes(data.recipe.garnish)}
                      </p>
                    </>
                  )}
                </div>
              </div>
              {slider && (
                <div className='dots'>
                  {[...Array(slider.details().size).keys()].map((item, idx) => {
                    return (
                      <button
                        key={idx}
                        onClick={() => slider.moveToSlideRelative(idx)}
                        className={
                          "dot" + (currentSlide === idx ? " active" : "")
                        }
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </OrnateWrapper>
        </div>
      </SlideUpTrayWrapper>
    </>
  );
};

export default SlideUpTray;
