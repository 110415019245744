import { useState, useEffect, useRef } from "react";
import QRCode from "qrcode";
import styled from "styled-components";

const QrWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const QrCode = ({ bg, fg, url }) => {
  const [qrCode, setQrCode] = useState(null);
  const qrRef = useRef(null);

  const generateQR = async () => {
    const qrOptions = {
      errorCorrectionLevel: "L",
      type: "svg",
      margin: 0,
      color: {
        dark: fg,
        light: bg,
      },
    };

    try {
      const result = await QRCode.toString(url, qrOptions);
      setQrCode(result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    generateQR();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!qrCode) return;
    // console.log(qrCode);
    qrRef.current.innerHTML = qrCode;
  }, [qrCode]);

  return <QrWrapper className='qr-code' ref={qrRef} />;
};

export default QrCode;
