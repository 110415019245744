import styled from "styled-components";

import Camera from "./Camera";
import Motion from "./Motion";
import Browser from "./Browser";
import Desktop from "./Desktop";

import { useConfig } from "../../global/ConfigState";

const ErrorScreensWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: var(--baseLight);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${"" /* padding: 1rem 1.5rem; */}
  z-index: 99999;
  pointer-events: auto;

  .heading {
    margin-bottom: 1rem;
  }
`;

const Error = () => {
  const { error } = useConfig();

  return (
    <ErrorScreensWrapper>
      {error === "camera" && <Camera />}
      {error === "motion" && <Motion />}
      {error === "browser" && <Browser />}
      {error === "desktop" && <Desktop />}
    </ErrorScreensWrapper>
  );
};

export default Error;
