import styled from "styled-components";

import { useConfig } from "../../global/ConfigState";

import { usePC } from "@unbnd-operations/react-hooks";

const SetupWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
  justify-content: flex-end;
  text-align: center;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.25);
  z-index: 20;

  font-size: 1.438rem;
  font-family: var(--primaryFont);
  color: var(--primaryColour);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));

  .place-controller {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: auto;
    z-index: 20;
  }

  .instruction {
    z-index: -1;
  }

  .tip {
    font-size: 1.25rem;

    text-decoration: none;
    color: white;

    top: 10%;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }

  .scan-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    height: 50vw;
    max-width: 168px;
    max-height: 168px;

    svg {
      width: 100%;
      height: 100%;
      stroke: #ffffff;
    }
  }

  p {
    text-decoration: underline;
    font-size: 1.375rem;
    line-height: 1.375rem;
    text-underline-offset: 0.25em;
  }

  .complete-btn {
    background-color: var(--placementBase);
    height: 52px;
    width: 52px;
    border-radius: 26px;
    margin: 1rem auto;
    display: grid;
    place-items: center;
    pointer-events: auto;

    img {
      margin: 0;
      filter: invert(1);
    }
  }

  .setup-copy-wrapper {
    position: relative;
  }
`;

const Setup = () => {
  const { arFound, placeAR, completeSetup } = useConfig();

  const [triggerTapPlace] = usePC({
    event: "ar:tapPlace",
    debug: true,
  });
  const handlePlace = () => {
    if (!arFound) return;
    triggerTapPlace();
    placeAR(true);
    completeSetup();
  };

  return (
    <SetupWrapper id='setup-screen'>
      <div className='setup-copy-wrapper'>
        {arFound && (
          <div className='place-controller' onClick={handlePlace}></div>
        )}
        <p className='tip'>
          Place on flat surface and avoid shiny or single colour surfaces
        </p>
        <p className='instruction'>
          {!arFound
            ? "Move device forward and back"
            : "Tap here to confirm positioning"}
        </p>
        <p className='tip'>
          {!arFound ? (
            <>{/* <b>Tip:</b> Look for a table for the best experience */}</>
          ) : (
            <>&nbsp;</>
          )}
        </p>
      </div>
    </SetupWrapper>
  );
};

export default Setup;
