import styled from "styled-components";

import { useMainData, useRegionData } from "../../../global/AppStore";

import ReactGA from "react-ga";

import BorderHeading from "../layout-elements/BorderHeading";
import OrnateWrapper from "../layout-elements/OrnateWrapper";
import FinIcon from "../layout-elements/FinIcon";
import OrnateTitle from "../layout-elements/OrnateTitle";
import OutlinedButton from "../../library/buttons/OutlinedButton";
import LyresContactForm from "../../form/LyresContactForm";

import BackArrowIcon from "../../../assets/images/icons/back-arrow.svg";
import OrnateUnder from "../../../assets/images/icons/ornate_under.svg";

const LatestOffersWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  background: var(--background);
  transition: opacity 250ms;
  overflow: hidden;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  .inner-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  .close-btn {
    position: absolute;
    top: 1.2rem;
    left: 1rem;
    z-index: 3;
    padding: 0.5rem;
  }
  .about-product-hero {
    margin-bottom: 20px;
  }
  .border-heading-wrapper {
    margin-bottom: 1rem;
  }
  .space-div {
    width: 100px;
    height: 100%;
  }

  .description-section {
    font-family: var(--headingFont);
    color: var(--black);
    padding: 0 2rem;
    font-size: 0.875rem;
    margin-bottom: 20px;
  }

  .form-wrapper {
    background-color: #fff;
    position: relative;

    .ornate-under {
      margin-top: 1rem;
    }

    &.before {
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 150px;
        background-color: #fff;
        top: -150px;
        left: 0;
        z-index: 1;
      }
    }

    .form-heading {
      padding-top: 40px;
      font-size: 2rem;
      color: var(--secondaryColour);
    }
  }
  .form-ornate-wrapper {
    height: auto;
    .fin-icon-wrapper {
      padding-top: 42px;
      p {
        margin-bottom: 52px;
      }
    }
  }

  .offer-wrapper {
    .ornate-title-wrapper {
      margin-bottom: 18px;
    }
    .offer-image {
      margin-bottom: 20px;
    }
    .offer-title {
      font-size: 1.625rem;
      font-family: var(--primaryFont);
      color: var(--secondaryColour);
      margin-bottom: 10px;
    }
    .offer-description {
      margin-bottom: 20px;
      font-size: 0.875rem;
    }
    .btn-outlined {
      margin-bottom: 50px;
      font-family: var(--primaryFont);
      font-size: 1.125rem;
    }
  }

  .no-underline {
    text-decoration: none;
    color: var(--secondaryColour);

    &:hover {
      var(--secondaryColour);
    }
  }
`;

const LatestOffers = ({ active, close }) => {
  const mainData = useMainData();
  const region = useRegionData();

  const offers = mainData.latest_offers.offers;
  const hero_image = mainData.latest_offers.hero_image;

  return (
    <LatestOffersWrapper
      className={`latest-offers-wrapper ${active ? "active" : ""}`}
    >
      <div className='inner-wrapper'>
        <button className='close-btn' onClick={close}>
          <img src={BackArrowIcon} alt='Back Arrow Icon' />
        </button>

        <div className='about-product-hero'>
          <img src={hero_image} alt='Offers Header' />
        </div>

        <section className='description-section'>
          <BorderHeading
            fontSize={2} //rems
            wPadding={20}
            className='title-product'
            isFullWidth={false}
          >
            Latest Offers
          </BorderHeading>

          {offers.map((item, index) => (
            <div key={index} className='offer-wrapper'>
              <OrnateTitle>
                <div className='space-div'></div>
              </OrnateTitle>
              <img
                className='offer-image'
                src={item.offer_image}
                alt={item.offer}
              />
              <div className='offer-title'>{item.offer}</div>
              <div className='offer-description'>{item.description}</div>
              <OutlinedButton>
                <a
                  className='no-underline'
                  href={item.offer_url[region.region.short]}
                  target='_blank'
                  onClick={() => {
                    ReactGA.event({
                      category: "Link Out",
                      action: item.offer,
                      label: item.offer_url[region.region.short],
                    });
                  }}
                >
                  {item.btn_copy}
                </a>
              </OutlinedButton>
            </div>
          ))}
        </section>
        <OrnateWrapper
          top={false}
          bottom={true}
          padding={15}
          className='form-ornate-wrapper'
        >
          <div className='form-wrapper'>
            <h2 className='form-heading'>
              Keep me up to date on the <br /> lyres world
            </h2>
            <img className='ornate-under' src={OrnateUnder} alt='Ornate' />

            <LyresContactForm />
          </div>
          <FinIcon />
        </OrnateWrapper>
      </div>
    </LatestOffersWrapper>
  );
};

export default LatestOffers;
