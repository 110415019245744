import styled from "styled-components";

const StyledOutlined = styled.button`
  padding: 1rem 3rem 0.7rem;
  box-shadow: 1px 1px 3px rgba(var(--black), 0.15);
  color: var(--secondaryColour);
  border: 1px solid var(--primaryColour);
  border-radius: 10px;
  line-height: 1;
  font-size: 1.125rem;
`;

const OutlinedButton = ({ children, onClick, className }) => {
  return (
    <StyledOutlined className={`btn-outlined ${className}`} onClick={onClick}>
      {children}
    </StyledOutlined>
  );
};

export default OutlinedButton;
