const FormInput = ({
  type,
  placeholder,
  name,
  title,
  handleChange,
  errorObj,
  currentValue,
}) => {
  return (
    <div className='form-group'>
      {errorObj && <span className='error-message'>{errorObj}</span>}
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        onChange={(e) => handleChange(e)}
        value={currentValue}
      />
      <label htmlFor={name}>{title}</label>
    </div>
  );
};

export default FormInput;
