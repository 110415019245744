import styled from "styled-components";

const CircleButtonWrapper = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  background-color: #fff;
  border: 3px solid var(--primaryColour);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primaryColour);
  font-family: var(--primaryFont);
  position: relative;
  pointer-events: auto;

  > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const CircleButton = ({ children, size, onClick, className }) => {
  return (
    <CircleButtonWrapper
      size={size ? size : 58}
      onClick={onClick}
      className={`circle-btn-wrapper ${className ? className : ""}`}
    >
      {children}
    </CircleButtonWrapper>
  );
};

export default CircleButton;
