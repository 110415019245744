import styled from "styled-components";
import FinIconImage from "../../../assets/images/icons/fin-icon.svg";

const FinIconWrapper = styled.div`
  font-family: var(--headingFont);
  font-size: 0.875rem;
  color: var(--secondaryColour);
  display: flex;
  flex-direction: column;
  background-color: #fff;
  font-weight: bold;
  img {
    width: 2rem;
    margin: 0 auto;
  }
  p {
    margin-top: 10px;
    margin-bottom: 70px;
  }
`;

const FinIcon = ({ className }) => {
  return (
    <FinIconWrapper
      className={`fin-icon-wrapper ${className ? className : ""}`}
    >
      <img src={FinIconImage} alt='Finish Icon' />
      <p>Fin.</p>
    </FinIconWrapper>
  );
};

export default FinIcon;
