import { useEffect } from "react";
import { usePC } from "@unbnd-operations/react-hooks";
import styled from "styled-components";

import CocktailMenu from "./UI/CocktailMenu";
import InteractBtn from "./layout-elements/InteractBtn";
import SlideUpTray from "./UI/SlideUpTray";
import { useCurrentBottle } from "../../global/AppStore";
import {
  useSetIntroInfo,
  useToggleTray,
  useCurrentCocktail,
  useIsRecipeBtnVisible,
  useSetRecipeBtnVisible,
  useIsCocktailMenuOpen,
} from "../../global/CocktailStore";

const CocktailUIWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &.blue-bg {
    background-color: var(--backgroundOverlay);
  }
`;

function CocktailUI({
  showUI,
  // cocktailHandler
}) {
  const [currentBottle] = useCurrentBottle();
  const setIntroInfo = useSetIntroInfo();

  const toggleTray = useToggleTray();

  const setIsRecipeBtnVisible = useSetRecipeBtnVisible();
  const isRecipeBtnVisible = useIsRecipeBtnVisible();
  const currentCocktail = useCurrentCocktail();
  const isCocktailMenuOpen = useIsCocktailMenuOpen();

  // usePC({
  //   event: "audio:tutorial:halfway",
  //   callback: () => {
  //     setIntroInfo("tutorialDone", true);
  //   },
  //   // debug: true,
  // });

  usePC({
    event: "audio:intro:end",
    callback: () => {
      setIntroInfo("introDone", true);
      setIntroInfo("tutorialDone", true);
    },
    // debug: true,
  });

  const [triggerCocktail] = usePC({
    event: "cocktail:show",
    debug: true,
  });

  usePC({
    event: "audio:recipe:end",
    callback: () => setIsRecipeBtnVisible(true),
  });

  useEffect(() => {
    if (!currentCocktail) return;

    triggerCocktail({ cocktailName: currentCocktail });
  }, [currentCocktail]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CocktailUIWrapper
      className={`cocktail-wrapper ${isCocktailMenuOpen ? "blue-bg" : ""}`}
    >
      <CocktailMenu />

      {isRecipeBtnVisible && showUI && (
        <InteractBtn
          viewRecipe={toggleTray}
          active={isRecipeBtnVisible && !isCocktailMenuOpen}
        />
      )}

      {currentCocktail && (
        <SlideUpTray
          data={currentBottle?.drinks.find(
            (currEl) => currentCocktail === currEl.id,
          )}
          // visible={isTrayOpen}
          // close={toggleTray}
          // first={!hasViewedRecipe}
        />
      )}
    </CocktailUIWrapper>
  );
}

export default CocktailUI;
