import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

import lyresStores from "../data/lyres-store-groups.json";

const lonGroups = [-180, -90, 0, 90, 180];
const latGroups = [-90, -45, 0, 45, 90];

initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

const db = getFirestore();

function isInbetween(val, min, max) {
  return val >= min && val < max;
}

function getGroup(val, groupArr) {
  let index;
  for (let i = 0; i < groupArr.length - 1; i++) {
    const min = groupArr[i];
    const max = groupArr[i + 1];

    if (isInbetween(val, min, max)) {
      index = i;
    }
  }
  return index;
}

function checkClosestStore(user, stores) {
  let closestStore;
  let closestDistance;

  for (let i = 0; i < stores.length; i++) {
    const distance = getDistance(user, stores[i]);
    // console.log(distance);

    if (closestDistance) {
      if (closestDistance > 0 && distance < closestDistance) {
        closestStore = stores[i];
        closestDistance = distance;
      }
    } else {
      closestStore = stores[i];
      closestDistance = distance;
    }
  }

  // console.log(`closestDistance`, closestDistance)

  return {
    ...closestStore,
    distance: closestDistance,
  };
}

function getDistance(user, store) {
  const earthRadius = 6371;
  const storeLat = Number(store.latitude);
  const storeLon = Number(store.longitude);

  const dLat = ((storeLat - user.latitude) * Math.PI) / 180;
  const dLon = ((storeLon - user.longitude) * Math.PI) / 180;

  const x =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((user.latitude * Math.PI) / 180) *
      Math.cos((storeLat * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const y = 2 * Math.asin(Math.sqrt(x));
  const z = earthRadius * y;
  const distance = z.toFixed(3);

  return distance;
}

async function handleUserLocation(userLocation, deviceInformation) {
  const lonIndex = getGroup(userLocation.longitude, lonGroups);
  const latIndex = getGroup(userLocation.latitude, latGroups);

  const closestStore = checkClosestStore(
    userLocation,
    lyresStores[lonIndex][latIndex],
  );

  if (closestStore.distance < 0.2) {
    try {
      await addDoc(
        collection(
          db,
          `user_location/${process.env.REACT_APP_FIREBASE_COLLECTION}/users`,
        ),
        {
          store: closestStore,
          device: deviceInformation,
        },
      );
    } catch (err) {
      console.log(err);
    }
  }
}

export default handleUserLocation;
