import React from "react";
import styled from "styled-components";

const StyledSolid = styled.button`
  ${"" /* padding: .75em 2em; */}
  background: transparent;
  // border-bottom: 2px solid var(--primaryColour);
  text-decoration: underline;
  text-underline-offset: 0.25em;
  box-shadow: 1px 1px 3px rgba(var(--black), 0.15);
  color: var(--primaryColour);
  font-family: var(--primaryFont);
  text-transform: uppercase;
`;

const SolidButton = ({ children, onClick, className }) => {
  return (
    <StyledSolid className={`btn-solid ${className}`} onClick={onClick}>
      {children}
    </StyledSolid>
  );
};

export default SolidButton;
