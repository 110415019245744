import { useConfig } from "../../global/ConfigState.js";

// import Rotate from "../../components/helpers/Rotate.js";
// import Reco from "./Reco";
import Experience from "./Experience";
import Setup from "./Setup.js";
import { useShowSelectMenu } from "../../global/AppStore.js";
import SelectMenu from "../../components/custom/UI/SelectMenu.js";
import LoadingScreen from "../../components/custom/UI/LoadingScreen.js";

const ARWrapper = () => {
  const { setupDone } = useConfig();
  const showSelectMenu = useShowSelectMenu();

  return (
    <>
      {/* <Rotate /> */}

      {/* <Reco /> */}
      <LoadingScreen />
      <SelectMenu isFirst={!setupDone} isOpen={showSelectMenu} />
      {!setupDone && <Setup />}
      <Experience active={setupDone} />
    </>
  );
};

export default ARWrapper;
