import { useState, Fragment } from "react";
import styled from "styled-components";

import { useConfig } from "../../../global/ConfigState";
import { usePC } from "@unbnd-operations/react-hooks";
import {
  useMainData,
  useRegionData,
  useSetShowReplaceButton,
  useSetShowSelectMenu,
  // useShowReplaceButton,
} from "../../../global/AppStore";

import ReactGA from "react-ga";

import SoundOnIcon from "../../../assets/images/icons/sound-on.svg";
import SoundOffIcon from "../../../assets/images/icons/sound-off.svg";
import MoreInfoIcon from "../../../assets/images/icons/information.svg";
import BackArrowIcon from "../../../assets/images/icons/back-arrow.svg";
import LyresType from "../../../assets/images/logos/lyres-type.svg";
import ShareIcon from "../../../assets/images/icons/share-icon.svg";
import VisitSiteIcon from "../../../assets/images/icons/visit-site-icon.svg";
import PrivacyIcon from "../../../assets/images/icons/privacy-policy-icon.svg";
import RecenterIcon from "../../../assets/images/icons/recenter.svg";
import ChangeBottleIcon from "../../../assets/images/icons/change-bottle.svg";

const NavbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  pointer-events: auto;
  align-items: center;

  .nav {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 3;
    position: relative;
  }

  .recenter-btn {
    margin-right: 16px;
  }

  .logo {
    width: auto;
    height: ${(props) => props.height}px;
    margin-bottom: 0;
  }
  .back-arrow {
    ${"" /* margin-top: 20px; */}
  }
  .more-info {
    // padding: 10px;
  }

  .wrapper {
    height: 3.5vh;
  }

  .change-bottle {
    height: auto;
  }

  .change-bottle-text {
    // padding-top: 0.25vh;
    font-family: var(--primaryFont);
    font-size: 1.2rem;
    color: white;
  }
`;

const CollapseMenu = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--background);
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: all 500ms ease-in-out;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .lyres-logo {
    width: 138px;
    position: absolute;
    top: 5%;
  }

  .about {
    span {
      ${"" /* font-size: 1.875rem; */}
      transform: translateX(-60vw) scale(0.5);
    }
  }

  .find-store {
    span,
    a {
      ${"" /* font-size: 1.500rem; */}
      transform: translateX(60vw) scale(0.5);
    }
  }

  .latest-offers {
    span {
      ${"" /* font-size: 1.250rem; */}
      transform: translateX(-60vw) scale(0.5);
    }
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
    .menu-item {
      span,
      a {
        font-size: 2.625rem;
        transform: translateX(0);
      }
    }
    .share-section {
      transform: translate(-50%, -10%) scale(1);
    }
  }

  .menu-item {
    color: var(--secondaryColour);
    font-family: var(--primaryFont);
    text-transform: uppercase;
    border-bottom: 3px solid var(--primaryColour);
    position: relative;
    height: 14vh;
    display: grid;
    place-items: center;

    span,
    a {
      display: block;
      transition: transform 550ms, opacity 550ms;
      position: relative;
    }

    button {
      text-align: left;
    }
  }

  ul {
    margin: 0.5rem 0 5rem;
    width: 100%;
  }
  .share-section {
    position: absolute;
    width: 100%;
    padding: 0 20%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 40%);

    font-family: var(--headingFont);
    color: var(--secondaryColour);
    transition: transform 500ms ease-in-out;

    .share-experience {
      font-size: 1.125rem;
      margin-bottom: 24px;
      img {
        margin-top: 14px;
      }
    }

    .bot-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 0.875rem;
      }

      .visit-site {
        p {
          margin-left: 8px;
        }
      }
    }
  }
`;

const menuItemsData = [
  {
    name: "About this Product",
    id: "about",
  },
  {
    name: "Find a Store",
    id: "find-store",
  },
  {
    name: "Latest Offers",
    id: "latest-offers",
  },
];

const Navbar = ({
  isOpen,
  setIsOpen,
  className,
  handleMenuItem,
  height = 40,
}) => {
  const [audioState, setAudioState] = useState(true);
  const setShowSelectMenu = useSetShowSelectMenu();
  const { replaceAR } = useConfig();
  // const showReplaceButton = useShowReplaceButton();
  const setShowReplaceButton = useSetShowReplaceButton();

  const mainData = useMainData();
  const { region } = useRegionData();

  const changeBottleBtn = () => {
    setIsOpen(false);
    setShowSelectMenu(true);
  };

  usePC({
    event: "bar:animation:end",
    callback: () => {
      setShowReplaceButton(true);
    },
  });

  const [audioMute] = usePC({
    event: "audio:mute",
  });
  const [audioUnmute] = usePC({
    event: "audio:unmute",
  });

  const audioOn = () => {
    setAudioState(true);
    audioUnmute();
  };

  const audioOff = () => {
    setAudioState(false);
    audioMute();
  };

  return (
    <NavbarWrapper id='navbar' height={height} className={className}>
      <nav className='nav'>
        <button className='more-info' onClick={() => setIsOpen(!isOpen)}>
          <img
            className={`${isOpen ? "back-arrow" : "more-info-icon"}`}
            src={!isOpen ? MoreInfoIcon : BackArrowIcon}
            alt='Sound on icon'
          />
        </button>

        <div className='wrapper'>
          {isOpen ? (
            <button className='sound' onClick={audioState ? audioOff : audioOn}>
              <img
                src={audioState ? SoundOnIcon : SoundOffIcon}
                alt='Sound on icon'
              />
            </button>
          ) : (
            <>
              {/* {showReplaceButton && ( */}
              <>
                <button className='recenter-btn' onClick={replaceAR}>
                  <img src={RecenterIcon} alt='recenter icon' />
                </button>
                <button className='change-bottle' onClick={changeBottleBtn}>
                  <img src={ChangeBottleIcon} alt='change bottle icon' />
                  <p className='change-bottle-text'>MENU</p>
                </button>
              </>
              {/* )} */}
            </>
          )}
        </div>
      </nav>

      <CollapseMenu
        height={height}
        className={`nav-menu ${isOpen ? "active" : ""}`}
      >
        <img className='lyres-logo' src={LyresType} alt='Lyres Logo' />
        <ul>
          {menuItemsData.map((item) => (
            <Fragment key={item.id}>
              {item.id === "find-store" && (
                <li key={item.id} className={`menu-item ${item.id}`}>
                  <a
                    href={mainData?.store_locator[region?.short || "World"]}
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => {
                      ReactGA.event({
                        category: "Link Out",
                        action: "Find Store",
                        label:
                          mainData?.store_locator[region?.short || "World"],
                      });
                    }}
                  >
                    {item.name}
                  </a>
                </li>
              )}
              {item.id !== "find-store" && (
                <li
                  key={item.id}
                  className={`menu-item ${item.id}`}
                  onClick={() => handleMenuItem(item.id)}
                >
                  <span>{item.name}</span>
                </li>
              )}
            </Fragment>
          ))}
        </ul>
        <div className='share-section'>
          <div
            className='share-experience'
            onClick={() => handleMenuItem("share")}
          >
            <p>Share the experience</p>
            <img src={ShareIcon} alt='Share Icon' />
          </div>

          <div className='bot-wrapper'>
            <div className='visit-site' onClick={() => handleMenuItem("visit")}>
              <img src={VisitSiteIcon} alt='Visit Website Icon' />
              <p>Lyre's Website</p>
            </div>
            <a
              href={mainData?.privacy_policy[region?.short || "World"]}
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                ReactGA.event({
                  category: "Link Out",
                  action: "Privacy Policy",
                  label: mainData?.privacy_policy[region?.short || "World"],
                });
              }}
            >
              <img src={PrivacyIcon} alt='Privacy policy icon' />
              <p>Privacy Policy</p>
            </a>
          </div>
        </div>
      </CollapseMenu>
    </NavbarWrapper>
  );
};

export default Navbar;
