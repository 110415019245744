import { usePC } from "@unbnd-operations/react-hooks";
import styled from "styled-components";

import HandWave from "../../../assets/images/icons/hand-waving.svg";

import CircleButton from "../layout-elements/CircleButton";

const CallBartenderWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--backgroundOverlay);

  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  z-index: 10;
  backdrop-filter: blur(5px);

  opacity: 0;
  pointer-events: none;
  transition: opacity 500ms linear;

  &.active {
    opacity: 1;
    .circle-btn {
      pointer-events: auto !important;
    }
  }

  .circle-btn {
    pointer-events: none !important;
    margin-bottom: 18px;

    img {
      width: 55%;
    }
  }

  .prompt-wrapper {
    padding: 0 10%;
  }

  .lyre-bird {
    width: 36px;
    margin-bottom: 1rem;
  }

  .prompt,
  .phone-steady {
    color: var(--primaryColour);
    text-transform: uppercase;
    text-decoration: underline;
    text-underline-offset: 0.25em;
    // border-bottom: 2px solid var(--primaryColour);
    font-family: var(--primaryFont);
    font-size: 1.375rem;
    margin-bottom: 60px;
  }

  .prompt {
    pointer-events: auto !important;
  }

  .phone-steady {
    border-bottom: none;
    margin-bottom: 24vh;
  }
`;

const CallBartender = ({ active, prompt, closePrompt }) => {
  const [startIntro] = usePC({
    event: "audio:intro",
  });

  const callBartenderBtn = () => {
    startIntro();
    closePrompt();
  };

  return (
    <CallBartenderWrapper
      className={`callbartender-wrapper ${active ? "active" : ""}`}
    >
      {/* <div className='prompt-wrapper'>
        <img className='lyre-bird' src={LyresBird} alt='Lyres Bird Icon' />
        <p className='phone-steady'>
          Keep Phone Steady and face the bottle at all times for the best
          experience.
        </p>
      </div> */}

      <CircleButton onClick={callBartenderBtn} className='circle-btn'>
        <img src={HandWave} alt='Hand Wave Icon' />
      </CircleButton>
      <p className='prompt' onClick={callBartenderBtn}>
        {prompt}
      </p>
    </CallBartenderWrapper>
  );
};

export default CallBartender;
