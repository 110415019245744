import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  :root {
    --primaryFont: "Bebas Neue", sans-serif;
    --headingFont: "Source Serif Pro", sans-serif;
    --primaryColour: ${(props) => props.theme.color.primary};
    --secondaryColour: ${(props) => props.theme.color.secondary};
    --background: ${(props) => props.theme.color.background};
    --backgroundOverlay: ${(props) => props.theme.color.backgroundOverlay};
    --backgroundOverlaySolid: ${(props) =>
      props.theme.color.backgroundOverlaySolid};
    --textColour: ${(props) => props.theme.color.text.normal};
    --invertedTextColour: ${(props) => props.theme.color.text.inverted};
    --baseDark: ${(props) => props.theme.color.base.dark};
    --black: ${(props) => props.theme.color.base.black};
    --baseLight: ${(props) => props.theme.color.base.light};
    --baseGrey: ${(props) => props.theme.color.base.grey};
    --placementError: ${(props) => props.theme.color.placement.error};
    --placementSuccess: ${(props) => props.theme.color.placement.success};
    --placementBase: ${(props) => props.theme.color.placement.base};
  }

  a {
    &:hover, 
    &:focus {
      color: var(--primaryColour);
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--primaryFont);
    line-height: 1.25;
    ${"" /* margin-bottom: .25em; */}
  }

  .main-heading {
    font-size: 2em;
  }

  .heading {
    font-size: 1.5em;
  }

  .sub-heading {
    font-size: 1em;
  }

  .scrollbar-remove {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }

  .coaching-overlay-prompt-status {
    display: none !important;
  }
`;
