import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ReactGA from "react-ga";

import { useConfig } from "../../global/ConfigState";

import handleUserLocation from "../../helpers/handleUserLocation";
import CookiePrompt from "../../components/library/cookie-prompt/CookiePrompt";
import SolidButton from "../../components/library/buttons/SolidButton";
import OrnateWrapper from "../../components/custom/layout-elements/OrnateWrapper";
import OrnateTitle from "../../components/custom/layout-elements/OrnateTitle";
import BorderHeading from "../../components/custom/layout-elements/BorderHeading";

import LyresBird from "../../assets/images/logos/lyres-bird.svg";
import LyresType from "../../assets/images/logos/lyres-type.svg";
import ArIcon from "../../assets/images/icons/ar-icon.svg";

import CameraIcon from "../../assets/images/icons/camera.svg";
import SoundIcon from "../../assets/images/icons/speaker-x.svg";

const LandingPageWrapper = styled.section`
  background: var(--background);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  pointer-events: none;

  .start-btn {
    position: absolute;
    left: 50%;
    bottom: 4rem;
    transform: translateX(-50%);
    font-size: 1.44rem;
    z-index: 3;
    pointer-events: auto;

    .ar-icon-wrapper {
      width: 58px;
      height: 58px;
      background-color: var(--background);
      border-radius: 51%;
      border: 2px solid var(--primaryColour);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 20px;
      img {
      }
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .initial-anim {
    width: 100%;
    height: 100%;
    ${"" /* background-color: ${styles.theme.background}; */}
    color: var(--primaryColour);
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 350ms ease-in-out;
    z-index: 300;

    &.active {
      opacity: 1;
    }

    .lyres-logo {
      > * {
        display: block;
        transform: scale(0.3);
        transform-origin: bottom center;
        transition: transform 1s;
        opacity: 0;
        margin: 0 auto;
      }

      > *:first-child {
        margin-bottom: 26px;
      }

      p {
        font-family: var(--headingFont);
        font-size: 1.75rem;
        text-transform: uppercase;
      }

      &.active {
        > * {
          transform: scale(1);
          opacity: 1;
        }
        .slogan {
          bottom: 20%;
        }
      }

      .slogan {
        position: absolute;
        bottom: -10%;
        left: 50%;
        transform: translateX(-50%);
        font-family: var(--primaryFont);
        font-size: 1.125rem;
        letter-spacing: 0.06rem;
        transition: bottom 1s;
      }
    }
  }

  .start-wrapper {
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 350ms;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    .ornate-intro-wrapper {
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
    }

    .video-wrapper {
      flex: 0 1 50%;
      width: 100%;
      position: relative;

      &::after {
        content: "";
        width: 100%;
        height: 60%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.71) 26.8%,
          rgba(12, 35, 64, 0) 100%
        );
      }
    }

    .intro-video {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.active {
      opacity: 1;
    }

    .main-title {
      text-align: center;
      padding: 40px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .lyre-bird {
        display: block;
        width: 28px;
        margin: 0 auto 6vh;
      }
      .landing-heading {
        width: 100%;
      }

      #ornate-title-wrapper {
        margin-bottom: 20px;
      }
    }
  }

  .prompt-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 38px;

    .camera-icon {
      width: 38px;
      margin-bottom: 50px;
    }
  }

  .prompt {
    color: var(--primaryColour);
    text-transform: uppercase;
    border-bottom: 2px solid var(--primaryColour);
    font-family: var(--primaryFont);
    font-size: 1.375rem;
    padding-bottom: 18px;
  }

  .age-gate {
    height: 100%;
    width: 100%;
    z-index: 100;

    opacity: 0;
    pointer-events: none;

    transition: all 0.4s ease-in-out;

    &.active {
      opacity: 1;
      pointer-events: auto;
    }

    position: absolute;
    background-color: white;

    .age-gate-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .lyre-bird {
        display: block;
        width: 28px;
        margin: 0 auto 6vh;
      }

      p {
        font-family: var(--primaryFont);
        font-size: 1.3rem;
        transition: bottom 1s;
        color: #d4a139;
        text-align: center;
      }

      .text-underlined {
        text-decoration: underline;
        text-underline-offset: 0.5rem;
      }

      button {
        font-family: var(--primaryFont);
        font-size: 1.2rem;
        padding: 0.75rem 1.4rem !important;
        transition: bottom 1s;
        border: 1px solid #d4a139;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        margin: 3rem;
        margin-bottom: 5rem;

        pointer-events: auto;
        color: black;
      }
    }
  }
`;

const LandingPage = ({ initializeGA }) => {
  const {
    arType,
    arStarted,
    setIntroReady,
    initialiseAR,
    deviceInfo,
    setError,
  } = useConfig();

  const [startClicked, setStartClicked] = useState(false);
  const [initialAnimComplete, setInitialAnimComplete] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const animRef = useRef(null);

  const handleStartClick = (e) => {
    e.stopPropagation();

    if (
      typeof DeviceMotionEvent !== "undefined" &&
      typeof DeviceMotionEvent.requestPermission === "function"
    ) {
      DeviceMotionEvent.requestPermission()
        .then((response) => {
          if (response == "granted") {
            navigator.mediaDevices
              .getUserMedia({ video: true })
              .then((response) => {
                console.log(response);
                if (navigator) {
                  navigator.geolocation.getCurrentPosition(
                    (pos) => {
                      handleUserLocation(
                        {
                          latitude: pos.coords.latitude,
                          longitude: pos.coords.longitude,
                        },
                        deviceInfo,
                      );

                      startAR();
                    },
                    (err) => {
                      startAR();
                    },
                  );
                } else {
                  startAR();
                }
              })
              .catch(() => {
                console.log("set error to camera");
                setError("camera");
                //! handle camera permission denied
              });
          } else {
            console.log("set error to camera");
            setError("motion");
            //! handle motion/orientation permission denied
          }
        })
        .catch(() => {
          console.log("set error to motion");
          setError("motion");
          //!: handle motion/orientation denied
        });
    } else {
      navigator.mediaDevices.getUserMedia({ video: true }).then((response) => {
        console.log(response);
        if (navigator) {
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              handleUserLocation(
                {
                  latitude: pos.coords.latitude,
                  longitude: pos.coords.longitude,
                },
                deviceInfo,
              );

              startAR();
            },
            (err) => {
              startAR();
            },
          );
        } else {
          startAR();
        }
      });
    }
  };

  const startAR = () => {
    initialiseAR();
    setStartClicked(true);

    ReactGA.event({
      category: "User",
      action: "Started Experience",
    });
  };

  const animComplete = () => {
    const to = setTimeout(() => {
      setInitialAnimComplete(true);
      clearTimeout(to);
    }, 500);
  };

  useEffect(() => {
    setTimeout(() => {
      setFadeIn(true);
    }, 500);
    const animationRef = animRef.current;
    animationRef.addEventListener("transitionend", animComplete, true);

    return () =>
      animationRef.removeEventListener("transitionend", animComplete, true);
  }, [animRef]);

  const [showAgeGate, setShowAgeGate] = useState(true);

  useEffect(() => {
    if (!arStarted) return;

    const soundTo = setTimeout(() => {
      setIntroReady(true);
      clearTimeout(soundTo);
    }, 3000);

    return () => {
      clearTimeout(soundTo);
    };
  }, [arStarted]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LandingPageWrapper id='landing-page'>
      <div className={`age-gate ${showAgeGate && "active"}`}>
        <OrnateWrapper
          top={true}
          bottom={false}
          padding={10}
          className='age-gate-content'
        >
          <img className='lyre-bird' src={LyresBird} alt="Lyre's Bird" />
          <p>TO ENTER THIS SITE</p>
          <p className='text-underlined'>YOU MUST BE AT LEAST 18 YEARS OLD</p>
          <button
            className='age-gate-button'
            onClick={() => setShowAgeGate(false)}
          >
            I AM AT LEAST 18 YEARS OLD
          </button>
          <p className='slogan'>Stay spirited. Drink Free</p>
        </OrnateWrapper>
      </div>

      <div className={`initial-anim ${!initialAnimComplete ? "active" : ""}`}>
        <div className={`lyres-logo ${fadeIn ? "active" : ""}`}>
          <img ref={animRef} src={LyresBird} alt="Lyre's Bird" />
          <img src={LyresType} alt='Lyres Type' />
          <p>Non-Alcoholic</p>
          <p className='slogan'>Stay spirited. Drink Free</p>
        </div>
      </div>

      <div className={`start-wrapper ${initialAnimComplete ? "active" : ""}`}>
        <OrnateWrapper
          top={true}
          bottom={false}
          padding={10}
          className='ornate-intro-wrapper'
        >
          <section className='main-title'>
            <img className='lyre-bird' src={LyresBird} alt="Lyre's Bird" />
            {!startClicked && (
              <>
                <OrnateTitle>Welcome To</OrnateTitle>
                <BorderHeading
                  borderColour={"#0C2340"}
                  borderSpace={10}
                  hPadding={10}
                  wPadding={0}
                  borderThickness={2}
                  fontSize={2.625} //rems
                  className='landing-heading'
                >
                  Lyre's Impossible Bar
                </BorderHeading>
              </>
            )}

            {startClicked && (
              <div className='prompt-wrapper'>
                {arStarted ? (
                  <>
                    <img
                      className='camera-icon'
                      src={SoundIcon}
                      alt='Camera Icon'
                    />
                    <h3 className='prompt'>
                      For the best experience
                      <br />
                      turn sound off silent mode
                    </h3>
                  </>
                ) : (
                  <>
                    <img
                      className='camera-icon'
                      src={CameraIcon}
                      alt='Camera Icon'
                    />
                    <h3 className='prompt'>
                      Please allow access to
                      <br />
                      camera, motion & orientation
                    </h3>
                  </>
                )}
              </div>
            )}
          </section>
        </OrnateWrapper>

        <div className='video-wrapper'>
          {!startClicked && (
            <SolidButton
              className={`start-btn ${startClicked ? "hidden" : ""}`}
              onClick={handleStartClick}
            >
              <div className='ar-icon-wrapper'>
                <img src={ArIcon} alt='AR Icon' />
              </div>
              Start Experience
            </SolidButton>
          )}

          <video
            src='https://storage.googleapis.com/lyres-bar.appspot.com/Intro_c_noAudio.mp4'
            playsInline={true}
            autoPlay={true}
            muted={true}
            className='intro-video'
            loop={true}
          ></video>
        </div>
      </div>

      {arType === "8thwall" && !initialAnimComplete && (
        <img
          className='eighth-wall-logo'
          src='//cdn.8thwall.com/web/img/almostthere/v2/poweredby-horiz-white-2.svg'
          alt='8th Wall Logo'
        />
      )}
    </LandingPageWrapper>
  );
};

export default LandingPage;
