export const validateValues = values => {
  let errors = {};

  if (!values.cd_firstName) {
    errors.cd_firstName = 'Full name is required';
  } else if (!/^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]+$/.test(values.cd_firstName)) {
    errors.cd_firstName = 'Enter a valid full name';
  }

  if (!values.cd_lastName) {
    errors.cd_lastName = 'Last Name is required';
  } else if (!/^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]+$/.test(values.cd_lastName)) {
    errors.cd_lastName = 'Enter a valid last name';
  }

  // if (!values.postcode) {
  //   errors.postcode = 'Postcode is required';
  // }

  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }

  // if (!values.mobile) {
  //   errors.mobile = 'Mobile number is required';
  // } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(values.mobile)) {
  //   errors.mobile = 'Mobile needs to be 10 numbers long';
  // }

  // if (!values.terms) {
  //   errors.terms = 'Please accept the T&Cs';
  // }
  // console.log(errors);
  return errors;
};

export const validatePhone = values => {
  let errors = {};

  if (!values.mobile) {
    errors.mobile = 'Mobile number is required';
  } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(values.mobile)) {
    errors.mobile = 'Mobile needs to be 10 numbers long';
  }

  return errors;
}