import styled from "styled-components";

const BorderHeadingWrapper = styled.div`
  font-family: var(--primaryFont);
  color: ${(props) => props.textColour};
  padding: ${(props) => props.hPadding}px ${(props) => props.wPadding}px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  h2 {
    font-size: ${(props) => props.fontSize}rem;
    width: ${(props) => props.isFullWidth};
    position: relative;
    display: inline-block;
    white-space: nowrap;
    letter-spacing: 0.05rem;

    &::before,
    &::after {
      content: "";
      width: 100%;
      height: ${(props) => props.borderThickness}px;
      background-color: ${(props) => props.borderColour};
      position: absolute;
    }

    &::before {
      top: -${(props) => props.borderSpace}px;
      left: 0;
    }

    &::after {
      bottom: ${(props) => -(props.borderSpace - 8)}px;
      left: 0;
    }
  }
`;

const BorderHeading = ({
  children,
  borderColour,
  textColour,
  fontSize,
  borderSpace,
  hPadding,
  wPadding,
  borderThickness,
  isFullWidth,
  className,
}) => {
  return (
    <BorderHeadingWrapper
      className={`border-heading-wrapper ${className ? className : ""}`}
      borderColour={borderColour ? borderColour : "#D4A139"}
      textColour={textColour ? textColour : "#0C2340"}
      borderSpace={borderSpace ? borderSpace : 17}
      hPadding={hPadding != null ? hPadding : 17}
      wPadding={wPadding ? wPadding : 17}
      borderThickness={borderThickness ? borderThickness : 2}
      fontSize={fontSize ? fontSize : 2.125}
      isFullWidth={isFullWidth ? "100%" : "auto"}
    >
      <h2>{children}</h2>
    </BorderHeadingWrapper>
  );
};

export default BorderHeading;
