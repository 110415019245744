import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { useConfig } from "../global/ConfigState";

import Loading from "./Loading/Loading";
import Landing from "./Landing/Landing";
import ARWrapper from "./Experience/ARWrapper";
import Error from "./Error/Error";
import CookiePolicy from "./CookiePolicy/CookiePolicy";
import CookiePrompt from "../components/library/cookie-prompt/CookiePrompt";
import FindStore from "../components/custom/UI/FindStore";
import { useFetchMainData, useGetRegion } from "../global/AppStore";

const Main = ({ initializeGA }) => {
  const { sceneLoaded, deviceInfo, error, introReady, setDesktopError } =
    useConfig();

  const fetchMainData = useFetchMainData();
  const setRegion = useGetRegion();

  useEffect(() => {
    if (!deviceInfo) return;

    if (deviceInfo.type === "desktop") {
      return setDesktopError();
    }

    const startScript = document.createElement("script");
    startScript.src = "/scene/__start__.js";
    document.body.appendChild(startScript);
  }, [deviceInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  // Get main data and region
  useEffect(() => {
    fetchMainData();
    setRegion();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [isPromptOpen, setIsPromptOpen] = useState(true);

  const onCookieAccept = () => {
    setIsPromptOpen(false);
    initializeGA();
  };

  const onCookieReject = () => {
    setIsPromptOpen(false);
  };

  return (
    <>
      {deviceInfo && (
        <Router>
          <Switch>
            <Route exact path='/find-store' component={FindStore} />
            <Route exact path='/cookie-policy' component={CookiePolicy} />
            <Route exact path='/'>
              <>
                {isPromptOpen && (
                  <CookiePrompt
                    onAccept={onCookieAccept}
                    onReject={onCookieReject}
                  />
                )}
                {error && <Error />}

                {!sceneLoaded ? (
                  <Loading />
                ) : (
                  <>
                    {introReady ? (
                      <ARWrapper />
                    ) : (
                      <Landing initializeGA={initializeGA} />
                    )}
                  </>
                )}
              </>
            </Route>
          </Switch>
        </Router>
      )}
    </>
  );
};

export default Main;
