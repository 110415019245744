import { useState, useRef, useEffect, forwardRef } from "react";
import HTMLFlipBook from "react-pageflip";
import styled from "styled-components";
import { usePC } from "@unbnd-operations/react-hooks";

import ReactGA from "react-ga";

import OrnateWrapper from "../layout-elements/OrnateWrapper";
import BorderHeading from "../layout-elements/BorderHeading";
import CircleButton from "../layout-elements/CircleButton";
import CocktailMenuPrompt from "../prompts/CocktailMenuPrompt";

import LyresBird from "../../../assets/images/icons/fin-icon.svg";
import OrnateUnder from "../../../assets/images/icons/ornate_under.svg";
import CoverImg from "../../../assets/images/cocktail-cover.png";
import LyresLogoFull from "../../../assets/images/logos/lyres-logo-full.svg";
import CocktailIcon from "../../../assets/images/icons/cocktail-icon.svg";
import HandSlide from "../../../assets/images/icons/hand-slide-icon.svg";
import BackArrow from "../../../assets/images/icons/back-arrow-circular.svg";

import { useCurrentBottle } from "../../../global/AppStore";
import {
  useIntroInfo,
  useIsCocktailMenuOpen,
  useIsGlow,
  useToggleCocktailMenu,
  useSetCurrentCocktail,
  useSetIntroInfo,
} from "../../../global/CocktailStore";

const MenuCover = forwardRef((props, ref) => {
  return (
    <div className='page cover' ref={ref} data-density='hard'>
      <img
        src={LyresLogoFull}
        alt='Lyres full logo'
        className='lyres-logo-full'
      />
      <div className='cover-wrapper'>
        <BorderHeading
          textColour={"#fff"}
          borderSpace={4}
          hPadding={0}
          wPadding={30}
          borderThickness={1}
          fontSize={1.9} //rems
          className='cocktail-menu-heading'
        >
          Cocktail Menu
          <img className='ornate-under' src={OrnateUnder} alt='Ornate Under' />
        </BorderHeading>
        <p className='slogan'>Stay spirited. Drink Free</p>
      </div>
    </div>
  );
});

const PageLeft = forwardRef((props, ref) => {
  return (
    <div className='page-left-wrapper' ref={ref} data-density='hard'>
      <div className='image-wrapper'>
        <span className='lines'></span>
        <img className='feature-image' src={props.featureImage} alt='Feature' />
        <img className='ornate-under' src={OrnateUnder} alt='Ornate Under' />
      </div>
    </div>
  );
});

const PageNext = forwardRef((props, ref) => {
  return (
    <div className='page-next-wrapper' ref={ref} data-density='hard'>
      <OrnateWrapper
        top={true}
        bottom={true}
        padding={8}
        size={22}
        className={"ornate-wrapper"}
      >
        <img className='lyres-bird' src={LyresBird} alt='Lyres Bird Icon' />
        <h2
          className='recipe-title'
          dangerouslySetInnerHTML={{ __html: props.title }}
        ></h2>
        <p className='recipe-excerpt'>{props.excerpt}</p>
        <p className='page-number'>
          Page {props.index + 1} of {props.currentBottle?.drinks.length}
        </p>
      </OrnateWrapper>
    </div>
  );
});

const CocktailMenuWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  color: #ffffff;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 6;
  transition: opacity 300ms linear 250ms;

  @keyframes glowPulse {
    50% {
      filter: drop-shadow(0 0 10px #fff);
    }
  }

  .stf__parent {
    transform: translate(-45%, 60%) scale(0.5);
    transition: transform 250ms linear;
    pointer-events: auto;
    padding: 5px;
  }

  &.visible {
    opacity: 1;
  }

  &.glow {
    .stf__parent {
      filter: drop-shadow(0 0 60px #fff);
      animation: glowPulse 2s infinite;
    }
  }

  &.open {
    .stf__parent {
      transform: translate(-25%, 0%) scale(1);
      filter: none;
    }
  }

  .page {
    background-color: #f8f7eb;
    ${"" /* border: 1px solid black; */}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .page-left-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      .image-wrapper {
        position: relative;
        .lines {
          &:before,
          &:after {
            content: "";
            width: 100%;
            background-color: var(--primaryColour);
            position: absolute;
          }

          &:before {
            top: -1rem;
            left: 0;
            height: 2px;
          }

          &:after {
            top: calc(-1rem + 4px);
            left: 0;
            height: 1px;
          }
        }
      }
    }

    .lyres-bird {
      width: 18px;
      display: block;
      margin: 0 auto 1rem;
    }

    &.--left {
      box-shadow: inset -7px 0 30px -7px rgb(0 0 0 / 40%);
    }
    &.--right {
      box-shadow: inset 7px 0 30px -7px rgb(0 0 0 / 40%);
    }
    .recipe-title {
      color: var(--primaryColour);
      width: 100%;
      position: relative;
      display: inline-block;
      &:before,
      &:after {
        content: "";
        width: 100%;
        background-color: var(--primaryColour);
        position: absolute;
      }

      &:before {
        bottom: -1rem;
        left: 0;
        height: 2px;
      }

      &:after {
        bottom: calc(-1rem + -3px);
        left: 0;
        height: 1px;
      }
    }

    .recipe-excerpt {
      font-size: 0.6rem;
      color: #000;
      margin-top: 2rem;
    }

    .ornate-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.8rem;
      font-family: var(--headingFont);
    }

    .page-number {
      position: absolute;
      bottom: 0.9rem;
      font-size: 0.5rem;
      color: #000;
    }
  }

  .ornate-under {
    width: 68px;
    position: absolute;
    bottom: -2rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .cover {
    width: 196px;
    height: 480px;
    background-image: url(${CoverImg});
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    ${"" /* pointer-events: auto; */}
    z-index: 10;
    .lyres-logo-full {
      position: absolute;
      top: 3rem;
      left: 50%;
      transform: translateX(-50%);
    }
    .cover-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &:after {
        content: "";
        position: absolute;
        top: 10px;
        left: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        border: 1px dashed rgba(255, 255, 255, 0.2);
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }

      .cocktail-menu-heading {
        position: relative;
      }

      .slogan {
        color: var(--primaryColour);
        text-transform: uppercase;
        font-family: var(--primaryFont);
        position: absolute;
        left: 50%;
        bottom: 2rem;
        transform: translateX(-50%);
        width: 100%;
        font-size: 0.75rem;
        letter-spacing: 0.06em;
      }
    }
    .back-cover {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      .behind-page {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .open-cocktail-menu {
    width: ${(props) => props.cMenuWidth}px;
    height: ${(props) => props.cMenuHeight}px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(20%, 237%);
    z-index: 10;
    pointer-events: auto;
  }
  .page-next-wrapper {
    width: 100%;
    height: 100%;
  }

  .cocktail-nav {
    width: 100%;
    padding: 0rem 1.25rem;
    position: absolute;
    bottom: 1.5rem;
    left: 0;
    height: 44px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: opacity 250ms;
    opacity: 0;
    pointer-events: none;
    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
  .make-my-lyres-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    .make-my-lyres-circular {
      width: 44px;
      height: 44px;
      img {
        width: 44%;
      }
    }

    span {
      margin-right: 12px;
      font-family: var(--primaryFont);
      color: var(--primaryColour);
      font-size: 1.2rem;
      padding-top: 3px;
    }
  }
`;

function CocktailMenu() {
  const [currentBottle] = useCurrentBottle();
  const selectCocktail = useSetCurrentCocktail();
  const { introDone, hasSeenCocktail, tutorialDone, firstMenuOpen } =
    useIntroInfo();
  const setIntroInfo = useSetIntroInfo();
  const isCocktailOpen = useIsCocktailMenuOpen();
  const glow = useIsGlow();
  const toggleCocktailMenu = useToggleCocktailMenu();

  const [showMenuPrompt, setShowMenuPrompt] = useState(true);
  const [currPage, setCurrPage] = useState(0);
  const [showCocktailMenuPrompt, setShowCocktailMenuPrompt] = useState(false);
  const [renderFlipBook, setRenderFlipBook] = useState(false);

  // Flip book stuff
  const flipBook = useRef(null);

  const cocktailMenuSize = {
    height: window.innerHeight * 0.6,
    width: window.innerWidth * 0.5,
  };

  const flipBookPages = currentBottle?.drinks.reduce((prev, curr, arrIndex) => {
    const pages = curr.pages.map((page) => ({
      ...page,
      index: arrIndex,
      drinkId: curr.id,
    }));
    return [...prev, ...pages];
  }, []);

  const [audioSelection] = usePC({
    event: "audio:selection",
  });

  const onFlip = (e) => {
    setCurrPage(e.data);
  };

  const closePrompt = () => {
    setShowMenuPrompt(false);
    setShowCocktailMenuPrompt(false);
  };

  const onChangeState = (e) => {
    if (e.data !== "flipping") return;
    const direction = e.object.flipController.app.render.direction;

    if (currPage === 1 && direction === 1) {
      document.querySelector(".stf__parent").style.transform =
        "translateX(-25%)";
    } else if (currPage === 0 && direction === 0) {
      document.querySelector(".stf__parent").style.transform = "translateX(0)";
    }
  };

  const handleCloseCocktailMenu = () => {
    if (!flipBook.current) return;
    flipBook.current.pageFlip().turnToPage(0);
    document.querySelector(".stf__parent").style.transform =
      "translate(-45%, 60%) scale(0.5)";

    // menuHandler.close();
    toggleCocktailMenu(false);
  };

  const openCocktailMenu = () => {
    document.querySelector(".stf__parent").style.transform =
      "translate(-25%, 0) scale(1)";
    // menuHandler.open();
    toggleCocktailMenu(true);
    if (!firstMenuOpen) return;
    setIntroInfo("firstMenuOpen", false);
  };

  const handleCocktail = (index) => {
    const drinkID = flipBookPages[index].drinkId;

    ReactGA.event({
      category: "Cocktail",
      action: "Cocktail Selected",
      label: drinkID,
    });

    // selectCocktail(drinkID);
    selectCocktail(drinkID);
    audioSelection({ firstTime: !hasSeenCocktail });
    setIntroInfo("hasSeenCocktail", true);
    handleCloseCocktailMenu();
  };

  useEffect(() => {
    if (currPage !== 1) return;
    if (!showMenuPrompt) return;

    setShowCocktailMenuPrompt(true);

    const closePromptTO = setTimeout(() => {
      closePrompt();
      clearTimeout(closePromptTO);
    }, 2500);

    return () => {
      clearTimeout(closePromptTO);
    };
  }, [showMenuPrompt, currPage]);

  useEffect(() => {
    const flipBookTO = setTimeout(() => {
      setRenderFlipBook(true);
      clearTimeout(flipBookTO);
    }, 500);

    return () => {
      clearTimeout(flipBookTO);
    };
  }, []);

  return (
    <>
      {renderFlipBook && (
        <CocktailMenuWrapper
          cMenuHeight={Math.floor(cocktailMenuSize.height * 0.5)}
          cMenuWidth={Math.floor(cocktailMenuSize.width * 0.5)}
          id='cocktail-menu'
          className={`
        ${introDone ? "visible" : ""} 
        ${isCocktailOpen ? "open" : ""} 
        ${glow ? "glow" : ""} 
      `}
        >
          {!isCocktailOpen && tutorialDone && (
            <div
              className='open-cocktail-menu'
              onClick={openCocktailMenu}
            ></div>
          )}
          <HTMLFlipBook
            width={Math.floor(cocktailMenuSize.width)}
            height={Math.floor(cocktailMenuSize.height)}
            size='fixed'
            showCover={true}
            onFlip={onFlip}
            onChangeState={onChangeState}
            ref={flipBook}
            useMouseEvents={true}
            drawShadow={false}
            maxShadowOpacity={0}
            renderOnlyPageLengthChange={true}
            swipeDistance={30}
          >
            <MenuCover />
            {flipBookPages.map((item, index, array) => (
              <div key={index} className={`page cocktail-${item.index + 1}`}>
                {index % 2 === 0 ? (
                  <>
                    <PageLeft featureImage={item.feature_image} />
                  </>
                ) : (
                  <>
                    <PageNext
                      title={item.title}
                      excerpt={item.excerpt}
                      index={item.index}
                      currentBottle={currentBottle}
                    />
                  </>
                )}
              </div>
            ))}
            {/* <MenuCover /> */}
          </HTMLFlipBook>

          <div className={`cocktail-nav ${isCocktailOpen ? "active" : ""}`}>
            <button className='back-btn' onClick={handleCloseCocktailMenu}>
              <img src={BackArrow} alt='Back Button Icon' />
            </button>

            {currPage > 0 && (
              <div
                className='make-my-lyres-btn'
                onClick={() => handleCocktail(currPage)}
              >
                <span>
                  Order now -<br></br>Make it a Lyre's
                </span>
                <CircleButton size={50} className='make-my-lyres-circular'>
                  <img src={CocktailIcon} alt='Cocktail Icon' />
                </CircleButton>
              </div>
            )}
          </div>

          <CocktailMenuPrompt
            active={showCocktailMenuPrompt}
            icon={HandSlide}
            close={closePrompt}
            prompt='Tap left or right<br>to read the menu'
          />
        </CocktailMenuWrapper>
      )}
    </>
  );
}

export default CocktailMenu;
