import ReactGA from "react-ga";
import { ThemeProvider } from "styled-components";

import ConfigState from "./global/ConfigState";
import { GlobalStyles } from "./styles/GlobalStyles";

import theme from "./styles/_theme";
import Main from "./pages/Main";

function App() {
  /**
   * Initalise Google Analytics
   */
  const initializeGA = () => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    console.log("initialized GA");
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <ConfigState>
        <Main initializeGA={initializeGA} />
      </ConfigState>
    </ThemeProvider>
  );
}

export default App;
