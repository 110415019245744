import styled from "styled-components";

const StyledTextarea = styled.textarea`
  resize: none;
  height: 200px;
  width: 100%;
`;

const FormTextarea = ({
  placeholder,
  name,
  title,
  handleChange,
  errorObj,
  currentValue,
}) => {
  return (
    <div className='form-group'>
      <StyledTextarea
        placeholder={placeholder}
        name={name}
        onChange={(e) => handleChange(e)}
        value={currentValue}
      />
      <label htmlFor={name}>{title}</label>
    </div>
  );
};

export default FormTextarea;
