import styled from "styled-components";

import OrnateWrapper from "../../components/custom/layout-elements/OrnateWrapper";
import CameraIcon from "../../assets/images/icons/camera.svg";
import LyreBird from "../../assets/images/logos/lyres-bird.svg";

const CameraErrWrapper = styled.div`
  width: 100%;
  height: 100%;

  .prompt {
    color: var(--primaryColour);
    text-transform: uppercase;
    border-bottom: 2px solid var(--primaryColour);
    font-family: var(--primaryFont);
    font-size: 1.375rem;
    padding-bottom: 18px;
  }

  .prompt-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 38px;

    .camera-icon {
      width: 38px;
      margin-bottom: 50px;
    }
  }
  .lyre-bird {
    width: 28px;
    position: absolute;
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Camera = () => {
  return (
    <CameraErrWrapper>
      <OrnateWrapper top={true} bottom={true} padding={20}>
        <img className='lyre-bird' src={LyreBird} alt='Lyre Bird' />

        <div className='prompt-wrapper'>
          <img className='camera-icon' src={CameraIcon} alt='Camera Icon' />
          <p className='prompt'>
            The camera permissions were denied on launch. Please refresh your
            browser and press allow on the pop-up.
          </p>
        </div>
      </OrnateWrapper>
    </CameraErrWrapper>
  );
};

export default Camera;
