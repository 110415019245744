import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from "react";
import styled from "styled-components";
import {
  useClearBottle,
  useCurrentBottle,
  useGetCurrentBottle,
  useMainData,
  useRegionData,
  useSetShowReplaceButton,
  useSetShowSelectMenu,
} from "../../../global/AppStore";

import ReactGA from "react-ga";

import { usePC } from "@unbnd-operations/react-hooks";

import { useResetIntroInfo } from "../../../global/CocktailStore";
import ArrowOutline from "../../../assets/images/icons/arrow-outline.svg";

import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { filterBottles } from "../../../helpers";

const SelectMenuWrapper = styled.div`
  background-color: var(--backgroundOverlaySolid);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
  padding: 1rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  .title {
    font-family: var(--primaryFont);
    font-size: 1.375rem;
    text-align: center;
    color: var(--primaryColour);
    text-decoration: underline;
    text-underline-offset: 0.25em;
    // border-bottom: 2px solid var(--primaryColour);
  }

  .title-wrapper {
    flex: 0 1 20%;
    position: relative;

    .back-arrow-select-menu {
      position: fixed;
      top: 1rem;
      left: 2rem;
    }
  }

  .grid {
    overflow-y: hidden;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    padding-bottom: 1rem;

    touch-action: none;
  }
  .grid-item {
    position: relative;
    border-radius: 15px;
    width: 158px;
    height: 197px;
    overflow: hidden;
    background-color: #ede4cf;

    display: flex;
    flex-direction: column;

    justify-content: space-evenly;
    align-items: center;

    &.active {
      border: 3px solid var(--primaryColour);
    }
    img {
      grid-row: 1 / span 1;
      grid-column: 1 / span 1;
      height: 87%;
    }

    h1 {
      font-size: 0.9rem;
      line-height: 0.9rem;
      height: 13%;
      color: #0c2340;
      text-align: center;
      margin-top: 0.15rem;

      grid-row: 2 / span 1;
      grid-column: 1 / span 1;
    }
  }

  .select-menu-btn {
    position: fixed;
    bottom: 1.5rem;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: var(--background);
    font-family: var(--primaryFont);
  }
`;

function SelectMenu({ isOpen }) {
  const mainData = useMainData();
  const [currentBottle, currentBottleId] = useCurrentBottle();
  const getCurrentBottle = useGetCurrentBottle();
  const setShowSelectMenu = useSetShowSelectMenu();
  const setShowReplaceButton = useSetShowReplaceButton();
  const { region } = useRegionData();

  const clearBottle = useClearBottle();
  const resetIntroInfo = useResetIntroInfo();

  const [updateLabel] = usePC({
    event: "label:update",
  });

  const closeSelectMenu = () => {
    setShowSelectMenu(false);
  };

  const [{ y }, api] = useSpring(() => ({
    y: 0,
  }));

  const [containerHeight, setContainerHeight] = useState(0);
  const gridContainer = useRef(null);
  const grid = useRef(null);

  useEffect(() => {
    if (!gridContainer.current) return;
    if (!grid.current) return;

    setContainerHeight(
      gridContainer.current.offsetHeight - grid.current.offsetHeight,
    );
  }, [gridContainer]);

  const bind = useDrag(
    ({ down, offset: [ox, oy], velocity: [vx, vy], ...rest }) => {
      // console.log("rest", rest);

      api.start({
        y: oy,
        immediate: down,
      });
    },
    {
      from: () => [0, y.get()],
      bounds: { left: 0, right: 0, top: -containerHeight, bottom: 0 },
      axis: "y",
      rubberband: true,
    },
  );

  const handleBottleSelect = useCallback(
    (bottleID) => {
      if (bottleID === currentBottleId) {
        closeSelectMenu();
        return;
      }

      ReactGA.event({
        category: "Bottle",
        action: "Bottle Selected",
        label: bottleID,
      });

      clearBottle();
      resetIntroInfo();
      setShowSelectMenu(false);
      setShowReplaceButton(false);
      getCurrentBottle(bottleID);
      updateLabel({ bottleName: bottleID });
    },
    [currentBottle], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <SelectMenuWrapper
      className={`select-menu-wrapper ${isOpen ? "active" : ""}`}
    >
      <div className='title-wrapper'>
        {currentBottle && (
          <button
            className='back-arrow-select-menu'
            onClick={() => setShowSelectMenu(false)}
          >
            <img src={ArrowOutline} alt='back arrow button' />
          </button>
        )}
        <h4 className='title'>Select your bottle</h4>
      </div>
      <div className='grid' ref={grid}>
        <animated.div
          {...bind()}
          style={{ y }}
          ref={gridContainer}
          className='grid-container'
        >
          {mainData &&
            filterBottles(mainData.bottles, region).map((item, index) => (
              <Fragment key={index}>
                {item.id !== "highland_malt_700ml" && (
                  <div
                    className={`grid-item`}
                    onClick={() => handleBottleSelect(item.id)}
                  >
                    <img
                      className='range-image'
                      src={item.thumbnail}
                      alt='Product Thumbnail'
                    />
                    <h1>{item.title}</h1>
                  </div>
                )}
              </Fragment>
            ))}
        </animated.div>
      </div>
    </SelectMenuWrapper>
  );
}

export default SelectMenu;
