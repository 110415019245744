import styled from "styled-components";
import { useEffect, useState } from "react";
import { useKeenSlider } from "keen-slider/react";

import ReactGA from "react-ga";

import BackArrowIcon from "../../../assets/images/icons/back-arrow.svg";
import LyresType from "../../../assets/images/logos/lyres-type.svg";
import OrnateUnder from "../../../assets/images/icons/ornate_under.svg";

import BorderHeading from "../layout-elements/BorderHeading";
import OrnateWrapper from "../layout-elements/OrnateWrapper";

import FinIcon from "../layout-elements/FinIcon";

import AwardWinningBadge from "../../../assets/images/award-winning.svg";

import OutlinedButton from "../../library/buttons/OutlinedButton";
import OrnateTitle from "../layout-elements/OrnateTitle";
import LyresContactForm from "../../form/LyresContactForm";
import {
  useMainData,
  useRegionData,
  useCurrentBottle,
} from "../../../global/AppStore";

import { filterBottles } from "../../../helpers";

const AboutPopupWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  background: var(--background);
  transition: opacity 250ms;
  overflow: hidden;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  .inner-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  .close-btn {
    position: absolute;
    top: 1.2rem;
    left: 1rem;
    z-index: 3;
    padding: 0.5rem;
  }
  .about-product-hero {
    margin-bottom: 20px;
  }
  .border-heading-wrapper {
    margin-bottom: 2rem;
  }
  .space-div {
    width: 100px;
    height: 100%;
  }

  .bottle {
    width: 120px;
    margin: 0 auto;
    position: relative;
    img {
      transform: translateY(-20px);
    }
    .discount-badge {
      position: absolute;
      right: -2.5rem;
      top: 35%;
      z-index: 2;
    }
    .award-winning-badge {
      position: absolute;
      bottom: 10%;
      right: -2.5rem;
    }
  }

  .description {
    font-family: var(--headingFont);
    color: var(--black);
    padding: 0 2rem;
    font-size: 0.875rem;
    margin-bottom: 20px;
  }

  .btn-outlined {
    font-family: var(--primaryFont);
    margin-bottom: 50px;
  }
  .lyres-type {
    width: 140px;
    margin-top: 4rem;
    margin-bottom: 20px;
  }
  .finest {
    font-family: var(--headingFont);
    font-size: 0.875rem;
    color: var(--black);
    padding: 0 2rem;
    margin-bottom: 40px;
  }

  .keen-slider {
    z-index: 2;
    margin-bottom: 46px;
    .range-image {
      width: 60%;
    }
  }

  .form-ornate-wrapper {
    .fin-icon-wrapper {
      padding-top: 58px;
    }
  }

  .form-wrapper {
    background-color: #fff;
    position: relative;

    .ornate-under {
      margin-top: 1rem;
    }

    &.before {
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 150px;
        background-color: #fff;
        top: -150px;
        left: 0;
        z-index: 1;
      }
    }

    .form-heading {
      padding-top: 1rem;
      font-size: 2rem;
      color: var(--secondaryColour);
    }
  }

  .bottle-thumbnail {
    padding: 1rem;
    background-color: #ede4cf;
    border-radius: 10px;
    height: 200px;
    width: 160px;
    display: grid;
    place-items: center;
    overflow: hidden;
  }

  .no-underline {
    text-decoration: none;
    color: var(--secondaryColour);

    &:hover {
      var(--secondaryColour);
    }
  }
`;

const AboutPopup = ({ active, close, data }) => {
  const { information, title } = data;

  const [currentBottle] = useCurrentBottle();

  const mainData = useMainData();
  const { region } = useRegionData();

  const [buyNowURL, setBuyNowURL] = useState("");

  const [sliderRef] = useKeenSlider({
    slidesPerView: 2.5,
    mode: "free-snap",
    spacing: 15,
    centered: true,
    loop: false,
  });

  useEffect(() => {
    if (!mainData || !currentBottle?.id || !region) return;

    setBuyNowURL(
      mainData.bottles.find((item) => item.title === currentBottle.title)
        .buy_now_url[region.short],
    );
  }, [mainData, currentBottle, region]);

  return (
    <AboutPopupWrapper
      className={`about-popup-wrapper ${active ? "active" : ""}`}
    >
      <div className='inner-wrapper'>
        <button className='close-btn' onClick={close}>
          <img src={BackArrowIcon} alt='Back Arrow Icon' />
        </button>

        <div className='about-product-hero'>
          <img
            src={information.about_product_header}
            alt='About Product Header'
          />
        </div>

        <section className='description-section'>
          <BorderHeading
            fontSize={2} //rems
            className='title-product'
          >
            {title}
          </BorderHeading>
          <OrnateTitle>
            <div className='space-div'></div>
          </OrnateTitle>
          <div className='bottle'>
            <img src={information.bottle_image} alt={title} />

            <img
              className='award-winning-badge'
              src={AwardWinningBadge}
              alt='Award winning badge'
            />
          </div>
          <p className='description'>{information.about_product.description}</p>

          <OutlinedButton>
            <a
              className='no-underline'
              href={buyNowURL}
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                ReactGA.event({
                  category: "Link Out",
                  action: "Buy Now",
                  label: buyNowURL,
                });
              }}
            >
              {information?.about_product.btn_copy}
            </a>
          </OutlinedButton>

          <OrnateWrapper top={true} bottom={false} padding={15}>
            <img className='lyres-type' src={LyresType} alt="Lyre's Type" />
            <p className='finest'>
              The finest range of non-alcoholic classic spirits the world has
              ever seen
            </p>
          </OrnateWrapper>
          <BorderHeading
            borderColour='#D4A139'
            textColour='#D4A139'
            hPadding={17}
            wPadding={30}
            borderThickness={1}
            fontSize={2} //rems
            className='about-product'
          >
            Explore the lyre's range
          </BorderHeading>

          <div ref={sliderRef} className={`keen-slider`}>
            {mainData &&
              filterBottles(mainData.bottles, region).map((item, index) => (
                <a
                  key={index}
                  className='keen-slider__slide bottle-thumbnail'
                  href={item.buy_now_url[region.short]}
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    ReactGA.event({
                      category: "Link Out",
                      action: "Buy Now",
                      label: item.buy_now_url[region.short],
                    });
                  }}
                >
                  <img
                    className='range-image'
                    src={item.thumbnail}
                    alt='Product Thumbnail'
                  />
                </a>
              ))}
          </div>

          <OrnateWrapper
            top={false}
            bottom={true}
            padding={15}
            className={"form-ornate-wrapper"}
          >
            <div className={`form-wrapper before`}>
              <h2 className='form-heading'>
                Keep me up to date on the <br /> lyres world
              </h2>
              <img className='ornate-under' src={OrnateUnder} alt='Ornate' />

              <LyresContactForm />
            </div>
            <FinIcon />
          </OrnateWrapper>
        </section>
      </div>
    </AboutPopupWrapper>
  );
};

export default AboutPopup;
