import styled from "styled-components";

import { useConfig } from "../../global/ConfigState";

import Loader from "../../assets/images/loader.png";

const LoadingScreen = styled.section`
  width: 100%;
  height: 100%;
  background: var(--background);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  display: grid;
  place-items: center;
  overflow: hidden;
  pointer-events: none;

  @keyframes spin {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .loader-wrapper {
    color: var(--primaryColour);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    p {
      font-size: 1.375em;
      font-family: var(--primaryFont);
      letter-spacing: 0.06rem;
      text-transform: uppercase;
    }
  }

  .loader {
    animation: spin 1000ms infinite linear;
    transform-origin: center center;
    width: 48px;
    height: 48px;
    position: relative;
    margin-bottom: 0.75rem;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const Loading = () => {
  const { arType, deviceInfo, isXR8Android, isXR8iOS } = useConfig();
  // const [loadingProgress, setLoadingProgress] = useState(0); // Playcanvas loader

  // Loading progress (from PlayCanvas)
  // usePC({
  //   event: "preload:progress",
  //   callback: (progress) => {
  //     setLoadingProgress(progress);
  //   },
  // });

  return (
    <LoadingScreen id='loading-screen'>
      <div className='loader-wrapper'>
        <div className='loader'>
          <img src={Loader} alt='Loader' />
        </div>
        <p>STAY SPIRITED. MAKE IT A LYRE’S</p>
      </div>
      {(arType === "8thwall" ||
        (deviceInfo.os === "Android" && isXR8Android) ||
        (deviceInfo.vendor === "Apple" && isXR8iOS)) && (
        <img
          className='eighth-wall-logo'
          src='//cdn.8thwall.com/web/img/almostthere/v2/poweredby-horiz-white-2.svg'
          alt='8th Wall Logo'
        />
      )}
    </LoadingScreen>
  );
};

export default Loading;
