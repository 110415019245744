import { useState, useEffect } from "react";
import styled from "styled-components";

import { usePC } from "@unbnd-operations/react-hooks";

import Loader from "../../../assets/images/loader.png";
import { useCurrentBottle } from "../../../global/AppStore";

const LoadingScreenWrapper = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: opacity 0ms;
  pointer-events: none;
  background-color: var(--backgroundOverlaySolid);
  z-index: 99;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  &.active {
    opacity: 1;
    transform: opacity 0ms;
  }

  &.fadeOut {
    opacity: 0;
    transform: opacity 300ms;
  }

  .image-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    img {
      max-width: 184px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      overflow: hidden;

      &.loading {
        width: 65px;
        animation: spin 1000ms infinite linear;
        transform-origin: center center;
      }

      &.active {
        opacity: 1;
      }
    }

    p {
      color: #d4a139;
      font-family: var(--primaryFont);
      font-size: 1.375rem;
      line-height: 1.375rem;
      text-decoration: underline;
      text-underline-offset: 0.25em;
      position: absolute;
      white-space: nowrap;

      left: 50%;
      bottom: 10%;
      transform: translateX(-50%);
    }
  }

  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0);
    }

    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

function LoadingScreen() {
  const [currentBottle, currentBottleId] = useCurrentBottle(); // eslint-disable-line no-unused-vars

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (!currentBottleId) return;
    if (showLoader) return;

    // const loaderTO = setTimeout(() => {
    setShowLoader(true);
    // clearTimeout(loaderTO);
    // }, 450);
  }, [currentBottleId, setShowLoader]); // eslint-disable-line react-hooks/exhaustive-deps

  usePC({
    event: "bottle:ready",
    callback: () => {
      console.log("bottle loaded");
      setShowLoader(false);
    },
  });

  return (
    <LoadingScreenWrapper className={`${showLoader ? "active" : ""}`}>
      <div className='image-wrapper'>
        <img
          className={`loading ${showLoader ? "active" : ""}`}
          src={Loader}
          alt='Loading'
        />
        <p>STAY SPIRITED. MAKE IT A LYRE’S</p>
      </div>
    </LoadingScreenWrapper>
  );
}

export default LoadingScreen;
