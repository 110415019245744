import styled from "styled-components";
import TitleOrnate from "../../../assets/images/icons/title-ornate.svg";

const OrnateTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: 1.375rem;
    color: var(--primaryColour);
    font-family: var(--primaryFont);
  }

  .title-ornate {
    padding-right: 10px;
  }

  .right-ornate {
    transform: scaleX(-1);
  }
`;

const OrnateTitle = ({ children }) => {
  return (
    <OrnateTitleWrapper
      className='ornate-title-wrapper'
      id='ornate-title-wrapper'
    >
      <img
        className='title-ornate left-ornate'
        src={TitleOrnate}
        alt='Title Ornate'
      />
      <h2>{children}</h2>
      <img
        className='title-ornate right-ornate'
        src={TitleOrnate}
        alt='Title Ornate'
      />
    </OrnateTitleWrapper>
  );
};

export default OrnateTitle;
