import create from "zustand";
import shallow from "zustand/shallow";
import axios from "axios";

import regions from "../data/regions";

const checkRegion = (country) => {
  if (!country) {
    return regions[0];
  }

  const selectedRegion = regions.find(
    // (region) => region.countries?.includes("Qatar"),
    (region) => region.countries?.includes(country),
  );
  return selectedRegion ? selectedRegion : regions[0];
};

const useAppStore = create((set, get) => ({
  mainData: null,
  currentBottle: null,
  currentBottleId: null,
  region: null,
  units: "metric",
  loading: true,
  error: null,
  showSelectMenu: true,
  showReplaceButton: false,

  setShowReplaceButton: (showReplaceButton) => set({ showReplaceButton }),

  fetchMainData: async () => {
    try {
      const response = await fetch("./data/main-data.json");
      const mainData = await response.json();

      set(() => ({
        mainData,
        loading: false,
      }));
    } catch (error) {
      set(() => ({
        error,
        loading: false,
      }));
    }
  },
  getCurrentBottle: async (id) => {
    set(() => ({
      loading: true,
    }));

    const newBottle = get().mainData.bottles.find((bottle) => bottle.id === id);

    try {
      const response = await fetch(`./data/bottles/${newBottle.json_file}`);
      const data = await response.json();

      set(() => ({
        currentBottle: data,
        currentBottleId: id,
        loading: false,
      }));
    } catch (err) {
      set(() => ({
        error: err,
        loading: false,
      }));
    }
  },
  clearCurrentBottle: () => {
    set(() => ({
      currentBottle: null,
      currentBottleId: null,
    }));
  },
  setRegion: async () => {
    console.log("set region function");
    await axios
      .get(`https://ipapi.co/json`)
      .then((resp) => {
        const region = checkRegion(resp.data.country_name);

        console.log("REGION: ", region);

        set(() => ({
          region,
          units: region.measure,
        }));
      })
      .catch((err) => {
        set(() => ({
          error: err,
        }));
      });
  },
  setError: (error) => set(() => ({ error })),

  setShowSelectMenu: (showSelectMenu) => set(() => ({ showSelectMenu })),
}));

export const useMainData = () => useAppStore((state) => state.mainData);
export const useCurrentBottle = () =>
  useAppStore((state) => [state.currentBottle, state.currentBottleId], shallow);
export const useClearBottle = () =>
  useAppStore((state) => state.clearCurrentBottle);
export const useDataError = () => useAppStore((state) => state.error);
export const useLoading = () => useAppStore((state) => state.loading);
export const useRegionData = () =>
  useAppStore(
    (state) => ({ region: state.region, units: state.units }),
    shallow,
  );

export const useGetRegion = () => useAppStore((state) => state.setRegion);
export const useFetchMainData = () =>
  useAppStore((state) => state.fetchMainData);
export const useGetCurrentBottle = () =>
  useAppStore((state) => state.getCurrentBottle);
export const useShowSelectMenu = () =>
  useAppStore((state) => state.showSelectMenu);
export const useSetShowSelectMenu = () =>
  useAppStore((state) => state.setShowSelectMenu);

export const useShowReplaceButton = () =>
  useAppStore((state) => state.showReplaceButton);
export const useSetShowReplaceButton = () =>
  useAppStore((state) => state.setShowReplaceButton);
