import create from "zustand";
import shallow from "zustand/shallow";

const useCocktailStore = create((set) => ({
  isCocktailMenuOpen: false,

  toggleCocktailMenu: (newState) => {
    set((state) => ({
      isCocktailMenuOpen:
        newState === true || newState === false
          ? newState
          : !state.isCocktailMenuOpen,
    }));
  },

  isTrayOpen: false,
  isRecipeBtnVisible: false,
  currentCocktail: null,

  toggleTray: () => {
    set((state) => ({
      isTrayOpen: !state.isTrayOpen,
    }));
  },

  setIsRecipeBtnVisible: (isRecipeBtnVisible) => {
    set(() => ({
      isRecipeBtnVisible,
    }));
  },

  setCurrentCocktail: (currentCocktail) => {
    set(() => ({
      currentCocktail,
    }));
  },

  resetCocktailUi: () => {
    set((state) => ({
      isTrayOpen: false,
      isRecipeBtnVisible: false,
      currentCocktail: null,
      isCocktailMenuOpen: false,
      introInfo: {
        ...state.introInfo,
        tutorialDone: false,
        hasSeenCocktail: false,
        hasViewedRecipe: false,
      },
    }));
  },

  introInfo: {
    introDone: false,
    tutorialDone: false,
    firstMenuOpen: true,
    viewedPrompt: false,
    hasSeenCocktail: false,
    hasViewedRecipe: false,
  },

  setIntroInfo: (key, value) => {
    console.log(key, value);
    set((state) => ({
      introInfo: {
        ...state.introInfo,
        [key]: value,
      },
    }));
  },
  resetIntroInfo: () => {
    set((state) => ({
      introInfo: {
        introDone: false,
        tutorialDone: false,
        firstMenuOpen: true,
        viewedPrompt: false,
        hasSeenCocktail: false,
        hasViewedRecipe: false,
      },
    }));
  },
}));

export const useToggleCocktailMenu = () =>
  useCocktailStore((state) => state.toggleCocktailMenu);
export const useIsCocktailMenuOpen = () =>
  useCocktailStore((state) => state.isCocktailMenuOpen);

export const useSetCurrentCocktail = () =>
  useCocktailStore((state) => state.setCurrentCocktail);
export const useCurrentCocktail = () =>
  useCocktailStore((state) => state.currentCocktail);

export const useSetIntroInfo = () =>
  useCocktailStore((state) => state.setIntroInfo);
export const useIntroInfo = () =>
  useCocktailStore((state) => ({ ...state.introInfo }), shallow);

export const useIsTrayOpen = () =>
  useCocktailStore((state) => state.isTrayOpen);
export const useToggleTray = () =>
  useCocktailStore((state) => state.toggleTray);

export const useSetRecipeBtnVisible = () =>
  useCocktailStore((state) => state.setIsRecipeBtnVisible);
export const useIsRecipeBtnVisible = () =>
  useCocktailStore((state) => state.isRecipeBtnVisible);

export const useIsGlow = () =>
  useCocktailStore(
    (state) => state.introInfo.tutorialDone && state.introInfo.firstMenuOpen,
  );

export const useResetUI = () =>
  useCocktailStore((state) => state.resetCocktailUi);

export const useResetIntroInfo = () =>
  useCocktailStore((state) => state.resetIntroInfo);
