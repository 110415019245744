import { useState } from "react";
import styled from "styled-components";

const CollapseWrapper = styled.div`
  width: 100%;
  height: 100px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  border: 1px solid #2e2c2d;
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  font-weight: 400;
  z-index: 2;
  position: absolute;
  background-color: #fff;

  @media screen and (min-width: 1020px) {
    height: 120px;
  }

  button {
    width: 100%;
    border: none;
    padding: 5px 10px;
    text-align: left;
    background-color: #fff;
    font-weight: 300;
    min-height: 30px;
  }

  &.open {
    opacity: 1;
    pointer-events: auto;
  }
`;

const FormDropdown = ({ data, handleChange, currentValue }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const toggleDropdown = (e) => {
    e.preventDefault();
    setShowDropdown(!showDropdown);
  };

  const handleOptionClick = (e) => {
    e.preventDefault();
    handleChange(e);
    setShowDropdown(false);
    setIsTouched(true);
  };

  return (
    <div className={`form-group dropdown ${showDropdown ? "active" : ""}`}>
      <div className='dropdown-wrapper' type='dropdown' name={data.name}>
        <button
          className='collapse-btn'
          style={{
            color: isTouched ? "#000000" : `'rgba(0,0,0,0.6)'`,
            border: showDropdown ? `1px solid #000` : "1px solid #ccc",
          }}
          onClick={(e) => toggleDropdown(e)}
        >
          {currentValue ? currentValue : "Please Select.."}
        </button>
        <CollapseWrapper
          className={`collapse-wrapper ${showDropdown ? "open" : ""}`}
        >
          {data.options.map((item, index) => (
            <button
              key={index}
              name={data.name}
              value={item.value}
              onClick={(e) => handleOptionClick(e)}
            >
              {item.title}
            </button>
          ))}
        </CollapseWrapper>
      </div>
      <label
        htmlFor={data.name}
        style={{ color: showDropdown ? `#000000` : "#000000" }}
      >
        {data.title}
      </label>
    </div>
  );
};

export default FormDropdown;
