import React from "react";
import styled from "styled-components";

import ReactGA from "react-ga";

import QrCode from "../../components/helpers/QrCode";
import LyreBird from "../../assets/images/logos/lyres-bird.svg";
import OrnateWrapper from "../../components/custom/layout-elements/OrnateWrapper";
import OrnateTitle from "../../components/custom/layout-elements/OrnateTitle";
import BorderHeading from "../../components/custom/layout-elements/BorderHeading";
import { useMainData, useRegionData } from "../../global/AppStore";

const DesktopWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  overflow: hidden;

  @media screen and (max-width: 1050px) {
    display: block;
    overflow: scroll;
  }

  @media screen and (max-height: 850px) {
    height: auto;
  }

  .left {
    ${"" /* height: 100%; */}
    width: 100%;
    flex: 1 1 auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media screen and (max-width: 1050px) {
      height: 25%;
    }
  }
  .right {
    height: 100%;
    width: 100%;
    background: var(--background);
    position: relative;
    overflow: hidden;

    @media screen and (max-height: 800px) {
      overflow: scroll;
    }

    @media screen and (max-width: 1050px) {
      height: auto;
      ${"" /* overflow: scroll; */}
    }

    .right-half {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      @media screen and (max-width: 1050px) {
        display: block;
        padding: 0 0 20px 0;
      }
    }
  }

  .lyre-bird {
    width: 48px;
    ${
      "" /* position: absolute;
    top: 72px;
    left: 50%;
    transform: translateX(-50%); */
    }
    opacity: 1;
    @media screen and (max-width: 1050px) {
      opacity: 0;
      width: 20px;
    }
  }

  .error-heading {
    ${"" /* margin-bottom: 72px; */}
    h2 {
      font-size: 4.5rem;
      @media screen and (max-width: 1050px) {
        font-size: 2.5rem;
      }
      @media screen and (max-width: 1400px) {
        font-size: 3.5rem;
      }
    }
  }

  .ornate-title-wrapper {
    margin-bottom: 2vh;
    h2 {
      font-size: 2.375rem;
      @media screen and (max-width: 1050px) {
        font-size: 1.5rem;
      }
    }
  }

  .qr-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: var(--headingFont);

    @media screen and (max-width: 1050px) {
      flex-direction: column;
    }

    @media screen and (max-width: 1050px) {
      font-size: 14px;
    }
    #qr-code {
      background-color: #fff;
      max-width: 272px;
      padding: 28px;
      border-radius: 22px;
      @media screen and (max-width: 1050px) {
        padding: 18px;
        max-width: 226px;
        margin: 40px 0 10px;
      }
      p {
        margin-bottom: 18px;
      }
      .qr-code {
        width: 218px;
        height: 218px;
        margin: 0 auto;
        @media screen and (max-width: 1050px) {
          width: 150px;
          height: 150px;
        }
      }
    }

    .instruction-copy {
      font-size: 18px;
      @media screen and (max-width: 1050px) {
        font-size: 14px;
      }
    }

    #link {
      font-weight: bold;
      font-size: 20px;
      @media screen and (max-width: 1050px) {
        font-size: 16px;
      }
    }

    .or {
      font-family: var(--primaryFont);
      padding: 0 1.5rem;
      line-height: 1;
      position: relative;
      @media screen and (max-width: 1050px) {
        padding: 10px 0 5px;
      }
      &::before,
      &::after {
        content: "";
        width: 2px;
        height: 50px;
        position: absolute;
        background-color: #000;
        transform: translate(50%, 50%);
        @media screen and (max-width: 1050px) {
          width: 30px;
          height: 2px;
        }
      }
      &::before {
        left: 50%;
        transform: translate(50%, -150%);
        @media screen and (max-width: 1050px) {
          transform: translate(-200%, 5px);
        }
      }
      &::after {
        left: 50%;
        transform: translate(50%, 90%);
        @media screen and (max-width: 1050px) {
          transform: translate(100%, 5px);
        }
      }
    }
  }
  .button-section {
    font-size: 14px;
    width: 80%;
    font-family: var(--headingFont);
    margin: 0 auto;
    .tc-prompt {
      margin-top: 40px;
    }
    .btn-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      font-family: var(--primaryFont);
      font-size: 20px;
      .line {
        width: 2px;
        height: 28px;
        background-color: #000;
        margin: 0 60px;
      }
    }
  }
`;

const Desktop = () => {
  const mainData = useMainData();
  const { region } = useRegionData();

  return (
    <DesktopWrapper>
      <div className='left'>
        {/* <img src={DesktopErrImage} alt='Desktop error' /> */}
        <video
          src='https://storage.googleapis.com/lyres-bar.appspot.com/Intro_c_noAudio.mp4'
          playsInline={true}
          autoPlay={true}
          muted={true}
          className='intro-video'
          loop={true}
        ></video>
      </div>

      <div className='right'>
        <OrnateWrapper
          top={true}
          bottom={false}
          padding={26}
          size={100}
          className='right-half'
        >
          <img className='lyre-bird' src={LyreBird} alt='Lyre Bird' />
          <div className='heading-wrapper'>
            <OrnateTitle>Welcome To</OrnateTitle>
            <BorderHeading
              borderColour={"#0C2340"}
              borderSpace={10}
              hPadding={10}
              wPadding={0}
              borderThickness={2}
              className='error-heading'
            >
              Lyre's Impossible Bar
            </BorderHeading>
          </div>

          <div className='qr-section'>
            <div id='qr-code'>
              <p>Scan the code with your mobile device</p>
              <QrCode bg='#fff' fg='#000' url={window.location.href} />
            </div>
            <p className='or'>OR</p>
            <div className='instruction-copy'>
              <p>Visit</p>
              <p id='link'>{window.location.origin}</p>
              <p>on your mobile device</p>
            </div>
          </div>

          <div className='button-section'>
            <div className='btn-wrapper'>
              <a
                href={mainData?.privacy_policy[region?.short || "World"]}
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  ReactGA.event({
                    category: "Link Out",
                    action: "Privacy Policy",
                    label: mainData?.privacy_policy[region?.short || "World"],
                  });
                }}
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </OrnateWrapper>
      </div>
    </DesktopWrapper>
  );
};

export default Desktop;
