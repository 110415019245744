import { useEffect, useState, createContext, useContext } from "react";

import { usePC } from "@unbnd-operations/react-hooks";

import { checkDevice } from "@unbnd-operations/utilities";

const ConfigContext = createContext();

export const useConfig = () => useContext(ConfigContext);

const ConfigState = (props) => {
  /**
   * Settings
   */
  const isXR8Android = true,
    isXR8iOS = true,
    portraitEnabled = true,
    landscapeEnabled = true;

  /**
   * Device Related
   */
  const [error, setError] = useState(null); // Error Screens
  const [arType, setArType] = useState(null); // AR Technology
  const [deviceInfo, setDeviceInfo] = useState(null); // Device information

  /**
   * PlayCanvas Loading
   */
  const [sceneLoaded, setSceneLoaded] = useState(false); // Playcanvas ready

  /**
   * Setup phase
   */
  const [arStarted, setArStarted] = useState(false); // AR Started
  const [introReady, setIntroReady] = useState(false); // Intro Ready
  const [arFound, setArFound] = useState(false); // Surface found (WebXR)
  const [arPlaced, setArPlaced] = useState(false); // Object placed
  const [setupDone, setSetupDone] = useState(false); // Checkbox ticked

  // * Error screen handling
  // *-------------------------------------* /
  // Handle permission errors (camera, motion)
  usePC({
    event: "ar:error:permission",
    callback: ({ reason }) => {
      setError(reason);
    },
  });

  // Handle browser error
  usePC({
    event: "ar:error:browser",
    callback: () => {
      setError("browser");
    },
  });

  // Trigger a desktop error - {Available in context}
  const setDesktopError = () => {
    setError("desktop");
  };

  // * AR Type
  // *-------------------------------------* /
  // Check AR Type
  usePC({
    event: "ar:type",
    callback: ({ type }) => {
      setArType(type);
    },
  });

  // * PlayCanvas Loading
  // *-------------------------------------* /
  // Loading done function
  usePC({
    event: "start",
    callback: () => {
      setSceneLoaded(true);
    },
  });

  // * Start AR
  // *-------------------------------------* /
  // Initialise AR - {Avaiable in context}
  const [initialiseAR] = usePC({
    event: "ar:init",
    callback: () => {
      console.log("Initialise AR");
    },
  });

  // AR Ready
  usePC({
    event: "ar:started",
    // debug: true,
    callback: () => {
      setTimeout(() => {
        setArStarted(true);
      }, 1500);
    },
  });

  // * Placement functions
  // *-------------------------------------* /
  // Handle Surface Found
  usePC({
    event: "ar:surface:found",
    // debug: true,
    callback: () => {
      setArFound(true);
    },
  });

  // Setup Done
  const [completeSetup] = usePC({
    event: "ar:setup:done",
    // debug: true,
    callback: () => {
      setSetupDone(true);
    },
  });

  // Handle Place AR
  const [handleARPlace] = usePC({
    event: "ar:placed",
    // debug: true,
    callback: () => {
      setArPlaced(true);
    },
  });

  // Handle Putdown AR
  const [handleARPutdown] = usePC({
    event: "ar:putdown",
    // debug: true,
    callback: () => {
      setArPlaced(true);
    },
  });

  const [fireReplaceAR] = usePC({ event: "ar:replace" });

  const replaceAR = () => {
    setSetupDone(false);
    fireReplaceAR();
  };

  // PlaceAR Function - {Available in context}
  const placeAR = (firstPlace) => {
    if (firstPlace) {
      handleARPlace();
    } else {
      handleARPutdown();
    }
  };

  // PickupAR Function - {Available in context}
  const [pickupAR] = usePC({
    event: "ar:pickup",
    debug: true,
    callback: () => {
      setArPlaced(false);
    },
  });

  // * Get device information
  // *-------------------------------------* /
  const getDeviceInfo = async () => {
    const thisDevice = await checkDevice();
    setDeviceInfo(thisDevice);
  };

  useEffect(() => {
    getDeviceInfo();
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        isXR8Android,
        isXR8iOS,
        portraitEnabled,
        landscapeEnabled,
        deviceInfo,
        error,
        setError,
        arType,
        // loadingProgress,
        sceneLoaded,
        arStarted,
        arFound,
        arPlaced,
        setupDone,
        setDesktopError,
        initialiseAR,
        placeAR,
        replaceAR,
        completeSetup,
        pickupAR,
        setIntroReady,
        introReady,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};

export default ConfigState;
