import { Fragment } from "react";
import styled from "styled-components";
import { useForm } from "@unbnd-operations/react-hooks";

import { FormInput, FormDropdown, FormTextarea } from "./input-fields";

import sendIcon from "../../assets/images/icons/send-icon.svg";

const FormStyled = styled.form`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  ${"" /* pointer-events: auto; */}
  background-color: #fff;

  input,
  select {
    width: 100%;
    height: 40px;
    ${"" /* setting height to match dropdown height */}
    padding: 0.5rem 1.25rem;
    margin: 0.8rem 0;
    display: inline-block;
    border: 1px solid #ccc;
    ${"" /* border-radius: .25rem; */}
  }

  .form-group {
    flex: 1 1 100%;
    ${"" /* padding: .5rem .5rem; */}
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;

    position: relative;
    ${"" /* padding-bottom: 16px; */}

    @media screen and (min-width: 768px) {
      flex: 50%;
    }

    .InterestedIn {
      position: relative;
    }

    select {
      height: 100%;
      appearance: none;
      background-color: transparent;
    }

    label {
      display: none;
      width: 100%;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      font-family: var(--primaryFont);
    }
    input:placeholder {
      color: rgba(#000, 0.6);
    }
    input:focus {
      border: 1px solid var(--primaryColour);
      outline: none;
    }
  }

  .collapse-btn {
    background-color: #fff;
    appearance: none;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    font-size: 0.875rem;
    padding-left: 1.25rem;
    width: 100%;
    height: 40px;
    text-align: left;
    font-weight: 300;
    font-weight: 400;
    position: relative;
    z-index: 1;
  }

  .submit {
    background-color: var(--primaryColour);
    border: none;
    color: #fff;
    font-size: 0.875rem;
    text-transform: uppercase;
    width: 240px;
    padding: 0;
    line-height: 1;
    align-self: center;
    cursor: pointer;
  }

  .error-message {
    text-align: left;
    color: red;
    font-size: 0.75rem;
    font-style: italic;
    position: absolute;
    bottom: -2px;
    left: 12px;
  }
`;

const Form = ({ data, submit, validation }) => {
  const { handleChange, handleSubmit, values, errors } = useForm(
    data
      .map((item) => ({
        [item.name]: typeof item.value !== "undefined" ? item.value : "",
      }))
      .reduce((prev, curr) => ({ ...prev, ...curr }), {}),
    submit,
    validation,
  );

  return (
    <FormStyled onSubmit={handleSubmit} className='form' noValidate>
      {data.map((field, index) => (
        <Fragment key={index}>
          {field.type !== "dropdown" &&
            field.type !== "submit" &&
            field.type !== "textarea" &&
            field.type !== "hidden" && (
              <FormInput
                type={field.type}
                placeholder={field.placeholder}
                name={field.name}
                title={field.title}
                handleChange={handleChange}
                errorObj={errors[field.name]}
                currentValue={values[field.name]}
              />
            )}

          {field.type === "hidden" && (
            <input type={field.type} name={field.name} value={field.value} />
          )}

          {field.type === "dropdown" && (
            <FormDropdown
              data={field}
              currentValue={values[field.name]}
              handleChange={handleChange}
            />
          )}

          {field.type === "textarea" && (
            <FormTextarea
              name={field.name}
              title={field.title}
              placeholder={field.placeholder}
              errorObj={errors[field.name]}
              currentValue={values[field.name]}
              handleChange={handleChange}
            />
          )}

          {field.type === "submit" && (
            <div className='form-group'>
              <button
                onClick={handleSubmit}
                className='submit'
                type={field.type}
              >
                <img src={sendIcon} alt='send icon' />
              </button>
            </div>
          )}
        </Fragment>
      ))}
    </FormStyled>
  );
};

export default Form;
