import create from "zustand";
import shallow from "zustand/shallow";

const useScanStore = create((set) => ({
  isEverythingLoaded: false,
  isBottleTracked: false,
  isBarInitialised: false,
  setFoundBottle: (bottle) => {
    set((state) => ({
      ...state,
      bottleFound: bottle,
    }));
  },
  initialiseBar: () => {
    set((state) => ({
      ...state,
      isBarInitialised: true,
    }));
  },
  setIsEverythingLoaded: () => {
    set((state) => ({
      ...state,
      isEverythingLoaded: true,
    }));
  },
  setIsBottleTracked: (isTracked) => {
    set((state) => ({
      ...state,
      isBottleTracked: isTracked,
    }));
  },
}));

export const useInitialiseBar = () =>
  useScanStore(
    (state) => [state.isBarInitialised, state.initialiseBar],
    shallow,
  );

export const useIsEverythingLoaded = () =>
  useScanStore(
    (state) => [state.isEverythingLoaded, state.setIsEverythingLoaded],
    shallow,
  );

export const useIsBottleTracked = () =>
  useScanStore((state) => state.isBottleTracked);

export const useSetIsBottleTracked = () =>
  useScanStore((state) => state.setIsBottleTracked);
