import { useRef, useEffect } from "react";
import styled from "styled-components";

const FindStoreWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: var(--background);

  z-index: 10;

  .close-btn {
    position: absolute;
    top: 44px;
    left: 24px;
    z-index: 3;
  }
`;

function FindStore() {
  const FindStoreScript = useRef(null);

  useEffect(() => {
    if (!FindStoreScript.current) return;

    const startScript = document.createElement("script");
    startScript.type = "text/javascript";
    startScript.async = true;
    startScript.src = "https://www.storemapper.co/js/widget.js";
    startScript.setAttribute("data-storemapper-start", "2019,10,20");
    startScript.setAttribute("data-storemapper-id", "6523");

    FindStoreScript.current.appendChild(startScript);
  }, [FindStoreScript]);

  return (
    <FindStoreWrapper className='find-store' ref={FindStoreScript}>
      <div id='storemapper' style={{ width: "100%" }}></div>
    </FindStoreWrapper>
  );
}

export default FindStore;
